import { intl } from "@tci18n/vue2";import basicHelper from '@/view/common/basic';
import roleAPI from '../../api/role';


/**
 * Role edit fetch mixin
 */
export default {
  methods: {
    /**
     * 获取“角色类型”下拉列表
     */
    getRoleTypeSelectList() {
      roleAPI.roleTypeSelectList().then((res) => {
        if (res.code == 200 && res.data && res.data.length) {
          // “集团租户”展示“门店角色”
          if (this.isRoleTypeHotelEnable) {
            this.roleTypeEnum = res.data;
          } else {
            this.roleTypeEnum = res.data.filter((item) => item.roleType === 'GENERAL_ROLE');
          }
        }
      }).catch((error) => {
        console.error('Get role type select list error: ', error.message);
      });
    },

    /**
     * 获取“角色等级”下拉列表
     */
    getRoleLevelSelectList() {
      roleAPI.roleLevelSelectList().then((res) => {
        if (res.code == 200 && res.data && res.data.length) {
          this.roleLevelEnum = res.data;
        }
      }).catch((error) => {
        console.error('Get role level select list error: ', error.message);
      });
    },


    /**
     * 获取角色详情
     * @note “新增”操作：id 传空，获取系统及权限配置
     * @note “复制”操作：copyById 接口需要返回所有系统权限信息，以支持角色类型切换。如果被复制角色未配置过那些特殊处理的系统，则返回其初始权限数据
     */
    getRoleInfo() {
      let params,
        serviceAPI = roleAPI.roleInfo;
      if (this.isActionEdit) {
        params = { id: this.id };
      } else if (this.isActionCopy) {
        params = { id: this.id };
        serviceAPI = roleAPI.roleCopyInfo;
      }
      this.viewLoading(true);
      serviceAPI(params).then((res) => {
        if (res.code == 200 && res.data) {
          this.processRoleInfo(res.data);
        } else {
          this.$notice.error(res.message);
        }
        this.viewLoading(false);
      }).catch((error) => {
        this.viewLoading(false);
        this.$notice.error(intl.$t("系统异常，请稍后再试"));
        console.error('Get role info error: ', error.message);
      });
    },

    /**
     * 角色信息处理逻辑
     */
    processRoleInfo(data) {
      if (this.isActionEdit || this.isActionCopy) {
        if (this.isActionCopy) {
          data.roleName = '';
        }
        this.formData.roleName = data.roleName;
        this.formData.roleLevel = data.roleLevel;
        this.formData.roleType = data.roleType;
        this.formData.hotelVid = data.hotelVid;
      }
      // 从数据源中过滤“云平台”
      this.projectData = data.projectResources.filter((item) => item.projectCode !== basicHelper.XCLOUD_PROJECT_CODE);
      if (this.projectData.length) {
        this.selectPermissionRef.setData(this.projectData);
      }

      // 缓存原始角色数据，用于是否产品编辑行为判断
      this.originalData = data;
    },


    /** 新增/编辑 **/
    /**
     * 保存角色
     * @param { Array } projectData 项目权限数据
     * @param { Array } restIds 餐厅权限数据
     */
    saveRole(projectData, restIds) {
      const params = this.processFormData(this.formData, projectData, restIds);
      this.viewLoading(true);
      roleAPI.roleSave(params).then((res) => {
        this.viewLoading(false);
        if (res.code == 200) {
          let actionText;
          if (this.isActionAdd) {
            actionText = intl.$t("新增");
          } else if (this.isActionEdit) {
            actionText = intl.$t("编辑");
          } else {
            actionText = intl.$t("复制");
          }
          this.$notice.success(actionText + intl.$t("角色成功"));

          this.$emit('action', this.formData, this.isRoleTypeHotel);
          this.handleCloseClick(true);
        } else {
          this.$notice.error(res.message);
        }
      }).catch((error) => {
        this.viewLoading(false);
        this.$notice.error(intl.$t("系统异常，请稍后再试"));
        console.error('Save role error: ', error.message);
      });
    },

    /**
     * 构造表单数据
     * @param { Object } data 原始表单数据
     * @param { Array } projectData 项目权限数据
     * @param { Array } restIds 餐厅权限数据
     * @returns { Object } 提交表单数据
     */
    processFormData(data, projectData, restIds) {
      let form = {
        roleName: data.roleName.trim(),
        roleLevel: data.roleLevel,
        roleType: data.roleType,
        projectResources: projectData
      };
      if (restIds.length) {
        // “餐厅”权限单独提交
        form.restIds = restIds;
      }
      if (this.isRoleTypeHotel) {
        form.hotelVid = data.hotelVid;
      }
      if (this.isActionEdit) {
        form.roleId = this.id;
      }
      return form;
    }
  }
};