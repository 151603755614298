<template>
<div>
    <member-box ref="memberBox" refer="role" :title="activeRoleName" :loading="boxLoading" @page-change="handlePaginationOnChangeCallback" @edit="hanleTableItemEditCallback">
      <div class="action-box" slot="action">
        <div class="select-view">
          <el-select :value="filterForm.state">
            <el-option v-for="item in stateEnum" :key="item.value" :label="item.label" :value="item.value" @click.native="handleStateOptionClick(item.value)"></el-option>
          </el-select>
        </div>
        <div class="button-view">
          <div v-if="isMemberAddPermission" class="action-button__icon--basic action-button" :class="{ disabled: isActionDisabled }" @click="handleAddClick">
            <i class="el-icon-plus prefix-icon"></i>
            <span class="suffix-text">{{$t('添加成员')}}</span>
          </div>
          <div v-if="isMemberRemovePermission" class="action-button__icon--basic action-button red" :class="{ disabled: isActionDisabled }" @click="handleMemberRemoveClick">
            <span class="suffix-text">{{$t('移除成员')}}</span>
          </div>
        </div>
      </div>
    </member-box>

    <!-- 添加成员 -->
    <lz-modal v-model="showModal" name="select-member" width="586px" :title="modalConfig.title" :mask-closable="false" loading-enable :loading="modalLoading" footer-hide>
      <div slot="body" style="height: 580px;">
        <select-member v-if="showModal" v-model="showModal" @loading="(loading) => { modalLoading = loading }" @confirm="handleSelectMemberCallback"></select-member>
      </div>
    </lz-modal>

    <!-- 移除成员 -->
    <lz-dialog v-model="showDialog" name="remove-member" width="360px" :title="dialogConfig.title" :confirm-button-text="$t('确认')" :mask-closable="!dialogLoading" loading-enable :loading="dialogLoading" @confirm="handleDialogConfirmCallback"></lz-dialog>
  </div>
</template>

<script>
import { intl } from "@tci18n/vue2";import { mapState, mapGetters, mapMutations } from "vuex";
import MemberBox from './box.vue';
import SelectMember from './selectMember.vue';
import memberAPI from '../api/member';

/**
 * 角色成员列表
 * @module @/view/organization
 */
export default {
  name: "RoleListBox",
  components: {
    MemberBox,
    SelectMember
  },
  data() {
    return {
      boxLoading: false,
      modalLoading: false,
      // 添加成员
      showModal: false,
      modalConfig: {
        title: intl.$t("添加成员"),
        data: null
      },
      dialogLoading: false,
      // 移除成员弹窗
      showDialog: false,
      dialogConfig: {
        title: intl.$t("是否确认移除？"),
        data: null
      },

      filterForm: {
        // 状态
        state: 1
      },

      // 状态枚举数据源
      stateEnum: [
      { value: -1, label: intl.$t("查看全部") },
      { value: 1, label: intl.$t("只看启用成员") },
      { value: 0, label: intl.$t("只看停用成员") }]

    };
  },
  computed: {
    ...mapState('org', [
    'activeRole',
    'pageLoading']
    ),

    ...mapGetters([
    'pageButtonPermission']
    ),

    ...mapGetters('org', [
    'activeRoleId']
    ),

    // 当前选中的角色名称
    activeRoleName() {
      return this.activeRole ? this.activeRole.roleName : '';
    },

    // 是否具有成员添加权限
    isMemberAddPermission() {
      return this.pageButtonPermission('hrms/organization', 'addRoleUserButton');
    },

    // 是否具有成员移除权限
    isMemberRemovePermission() {
      return this.pageButtonPermission('hrms/organization', 'allDeleteRoleUserButton');
    },

    // 事件按钮是否 disabled
    isActionDisabled() {
      return this.activeRoleId === -1 || this.activeRoleId === '';
    },

    // 获取组件引用
    memberBoxRef() {
      return this.$refs['memberBox'];
    }
  },
  watch: {
    // 监听当前选择角色变更
    activeRoleId(newVal, oldVal) {
      this.memberBoxRef.resetView();
      if (newVal !== '') {
        this.getMemberListPage();
      }
    }
  },
  mounted() {
    if (!this.isActionDisabled) {
      this.getMemberListPage();
    }
  },
  methods: {
    ...mapMutations('org', [
    'setPageLoading']
    ),

    /**
     * 状态选项点击事件
     * @note 使用 click 事件以捕获当前“选中项”点击事件
     * @param { Number } value 当前点击选项值
     */
    handleStateOptionClick(value) {
      if (this.isActionDisabled) {
        return;
      }

      this.memberBoxRef.resetView();
      if (value !== this.filterForm.state) {
        // Select 下拉项非重复点击，重新获取数据
        this.filterForm.state = value;
        this.getMemberListPage();
      } else {
        // Select 下拉项重复点击，刷新当前页
        this.getMemberListPage();
      }
    },


    /** 添加相关 **/
    /**
     * 添加成员
     */
    addMember(data) {
      const params = {
        roleId: this.activeRoleId,
        userIds: data
      };
      this.modalLoading = true;
      memberAPI.memberAdd(params).then((res) => {
        this.modalLoading = false;
        if (res.code == 200) {
          this.$notice.success(intl.$t("添加成员成功！"));

          this.processAfterAdd();
        } else {
          this.$notice.error(res.message);
        }
      }).catch((error) => {
        this.modalLoading = false;
        this.$notice.error(intl.$t("系统异常，请稍后再试"));
        console.error('Add role member error: ', error.message);
      });
    },

    /**
     * 移除角色成员成功后处理逻辑
     * @note
     * - 移除数据会影响分页数据获取，需要刷新数据列表
     */
    processAfterAdd() {
      this.showModal = false;
      this.memberBoxRef.resetView();
      this.getMemberListPage();
    },

    /**
     * 添加成员点击事件
     */
    handleAddClick() {
      if (!this.isActionDisabled) {
        this.showModal = true;
      }
    },

    /**
     * 添加成员确认回调事件
     * @param { Array } data 选择的成员
     */
    handleSelectMemberCallback(data) {
      this.addMember(data);
    },


    /** 移除相关 **/
    /**
     * 移除成员
     */
    removeMember(data) {
      const params = {
        roleId: this.activeRoleId,
        userIds: data
      };
      this.dialogLoading = true;
      memberAPI.memberRemove(params).then((res) => {
        this.dialogLoading = false;
        if (res.code == 200) {
          this.$notice.success(intl.$t("移除成员成功！"));

          this.processAfterRemove();
        } else {
          this.$notice.error(res.message);
        }
      }).catch((error) => {
        this.dialogLoading = false;
        this.$notice.error(intl.$t("系统异常，请稍后再试"));
        console.error('Remove role member error: ', error.message);
      });
    },

    /**
     * 移除角色成员成功后处理逻辑
     * @note
     * - 移除数据会影响分页数据获取，需要刷新数据列表
     */
    processAfterRemove() {
      this.showDialog = false;
      this.memberBoxRef.resetView();
      this.getMemberListPage();
    },

    /**
     * 移除成员点击事件
     */
    handleMemberRemoveClick() {
      if (!this.isActionDisabled) {
        const data = this.memberBoxRef.getSelectedData();
        if (!data.length) {
          this.$notice.error(intl.$t("请选择要移除的成员"));
          return;
        }
        this.dialogConfig.data = data.map((item) => item.userId);
        this.showDialog = true;
      }
    },

    /**
     * 移除成员确认回调事件
     */
    handleDialogConfirmCallback() {
      this.removeMember(this.dialogConfig.data);
    },


    /**
     * 分页事件回调
     */
    handlePaginationOnChangeCallback() {
      this.getMemberListPage();
    },

    /**
     * 获取角色成员列表（分页）
     */
    getMemberListPage() {
      const params = this.processFilterData();
      if (!this.pageLoading) {
        this.boxLoading = true;
      }
      memberAPI.memberListPage(params).then((res) => {
        if (this.pageLoading) {
          this.setPageLoading(false);
        } else {
          this.boxLoading = false;
        }
        const memberBoxRef = this.$refs['memberBox'];
        if (res.code == 200 && res.data && memberBoxRef) {
          memberBoxRef.setData(res.data);
        }
      }).catch((error) => {
        if (this.pageLoading) {
          this.setPageLoading(false);
        } else {
          this.boxLoading = false;
        }
        this.$notice.error(intl.$t("系统异常，请稍后再试"));
        console.error('Get role member list error: ', error.message);
      });
    },

    /**
     * 处理筛选数据
     */
    processFilterData() {
      const pageData = this.memberBoxRef.getPageData();
      return {
        roleId: this.activeRoleId,
        state: this.filterForm.state !== -1 ? this.filterForm.state : '',
        pageIndex: pageData.currentPage,
        pageSize: pageData.pageSize
      };
    },

    /** 成员新增/编辑相关 **/
    /**
     * “编辑”事件回调
     * @param { Object } data 数据项
     */
    hanleTableItemEditCallback(data) {
      this.$emit('edit', 2, { id: data.userId, name: data.userName });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/const";

  .select-view {
    width: 140px;
    float: left;
  }

  .button-view {
    float: right;

    .action-button + .action-button {
      margin-left: 8px;
    }

    .action-button.red {
      color: $error-color;
      border-color: $error-color;
    }

    .action-button.disabled {
      cursor: not-allowed;
    }
  }
</style>
