<template>
<div class="page-box pt-20 pb-20">
    <div class="tab-section ml-20 mr-20 display__flex justify_content__space_between">
      <div :class="['tab-item', 'flex_grow__1', { actived: isPoolTypeDept }]" @click="handleTabSwitch('dept')">{{$t('组织架构')}}</div>
      <div :class="['tab-item', 'flex_grow__1', { actived: !isPoolTypeDept }]" @click="handleTabSwitch('role')">{{$t('角色')}}</div>
    </div>

    <div class="search-section mt-10 ml-20 mr-20" :class="{ expand: isSearchingState() }">
      <search-view ref="searchList" @change="handleSearchChangeCallback"></search-view>
    </div>

    <div v-show="!isSearchingState()" class="tree-section mt-10 ml-20">
      <dept-tree v-if="deptTreeRendered" v-show="isPoolTypeDept" ref="deptTree" node-key="deptId" :root-data="rootData" :hotelList="hotelList" @add="handleDeptTreeNodeAddCallback" @edit="handleDeptTreeNodeEditCallback" @delete="handleDeptTreeNodeDeleteCallback"></dept-tree>

      <role-tree v-if="roleTreeRendered" v-show="!isPoolTypeDept" ref="roleTree" :root-data="rootData" :hotelList="hotelList" @add="handleRoleTreeNodeAddCallback" @copy="handleRoleTreeNodeCopyCallback"></role-tree>
    </div>
  </div>
</template>

<script>
import { intl } from "@tci18n/vue2";import { mapState, mapGetters, mapMutations } from 'vuex';
import deptAPI from '../api/dept';
import roleAPI from '../api/role';
import commonAPI from '../../common/api';
import SearchView from './search.vue';
import DeptTree from './deptTree.vue';
import RoleTree from './roleTree.vue';

/**
 * 组织架构/角色池视图
 * @module @/view/organization
 */
export default {
  name: 'ResourcePool',
  components: {
    SearchView,
    DeptTree,
    RoleTree
  },
  data() {
    return {
      // 树视图根节点数据
      rootData: null,
      // 组织架构数据
      deptData: null,
      // 组织架构树视图是否已渲染
      deptTreeRendered: false,
      // 角色树视图是否已渲染
      roleTreeRendered: false,

      // 酒店列表
      hotelList: []
    };
  },
  computed: {
    ...mapState('org', [
    'poolType',
    'generalRoleData']
    ),

    ...mapGetters([
    'userOrgName',
    'isTenantTypeBlocUser']
    ),

    ...mapGetters('org', [
    'isPoolTypeDept',
    'isTreeRootNode',
    'activeDeptId']
    ),


    // “酒店列表”是否存在（“集团租户”用户存在）
    isHotelListShowable() {
      return this.isTenantTypeBlocUser;
    }
  },
  created() {
    this.getDeptTreeData();
    this.getRoleData(!this.isHotelListShowable);
  },
  methods: {
    ...mapMutations('org', [
    'setGeneralRoleData',
    'setPageLoading']
    ),

    /**
     * 资源类型切换
     * @param { String } type 类型
     */
    handleTabSwitch(type) {
      if (this.poolType !== type) {
        this.$store.commit('org/setPoolType', type);
      }

      if (this.isPoolTypeDept && !this.deptTreeRendered) {
        this.deptTreeRendered = true;
      } else if (!this.isPoolTypeDept && !this.roleTreeRendered) {
        this.roleTreeRendered = true;
        this.$nextTick(() => {
          this.$refs['roleTree'].setData(this.generalRoleData, this.hotelList);
        });
      }
    },


    /** 搜索相关 **/
    /**
     * 是否处于搜索状态
     */
    isSearchingState() {
      return this.$refs['searchList'] != undefined && this.$refs['searchList'].isSearching;
    },

    /**
     * 搜索项点击回调事件
     * @param { Object } data 数据项
     */
    handleSearchChangeCallback(data) {
      if (this.isPoolTypeDept) {
        if (data.type == 0) {
          // 成员
          this.$emit('edit-member', 2, data);
        } else {
          // 部门
          this.$refs['deptTree'].selectData(data);
        }
      } else {
        this.$refs['roleTree'].selectData(data);
      }
    },


    /** 组织架构相关  **/
    /**
     * 获取“部门”数据
     */
    getDeptTreeData() {
      deptAPI.deptTree().then((res) => {
        if (res.code == 200 && res.data) {
          this.processDeptTreeData(res.data);
        } else {
          this.setPageLoading(false);
          this.$notice.error(res.message);
        }
      }).catch((error) => {
        this.setPageLoading(false);
        this.$notice.error(intl.$t("系统异常，请稍后再试"));
        console.error('Get dept tree error: ', error.message);
      });
    },

    /**
     * “部门”数据处理
     */
    processDeptTreeData(data) {
      this.syncDeptTreeData(data);
      if (this.deptTreeRendered) {
        this.$refs['deptTree'].updateData(this.deptData);
      } else {
        this.deptTreeRendered = true;
        if (this.deptData.length > 0) {
          this.$nextTick(() => {
            let deptTreeRef = this.$refs['deptTree'];
            deptTreeRef.setData(this.deptData, this.hotelList);
            deptTreeRef.setDeptPageAction(this.$store.state.org.pageAction);
          });
        } else {
          this.setPageLoading(false);
        }
      }
    },

    /**
     * 同步组织架构数据源
     * @note 根节点数据显示为集团/租户节点，但需要根据当前用户所在架构判断是否具有根节点权限，若无权限则不能进行操作和选中；人数为可见部门人数
     * @note 根节点 deptId == 0
     * @note 可能是集团用户，也可能是租户用户
     */
    syncDeptTreeData(data) {
      if (data.length > 0) {
        const rootName = this.userOrgName;
        if (this.isTreeRootNode(data[0].deptId)) {
          this.rootData = {
            deptId: Number(data[0].deptId),
            deptName: rootName,
            deptPid: data[0].deptPid,
            permission: true
          };
          data[0].deptName = rootName;
          data[0].permission = this.rootData.permission;
        } else {
          const rootData = {
            deptId: 0,
            deptName: rootName,
            permission: false
          };
          this.rootData = { ...rootData };
          rootData.children = data;
          data = [rootData];
        }
      }
      this.$store.commit('org/setDeptTreeData', data);
      this.deptData = data;
    },


    /** 部门新增/编辑/删除 **/
    /**
     * 部门新增事件回调
     */
    handleDeptTreeNodeAddCallback() {
      // 刷新组织架构树
      this.getDeptTreeData();
    },

    /**
     * 部门编辑事件回调
     */
    handleDeptTreeNodeEditCallback() {
      // 刷新组织架构树
      this.getDeptTreeData();
    },

    /**
     * 部门删除事件回调
     */
    handleDeptTreeNodeDeleteCallback() {
      this.getDeptTreeData();
    },


    /** 角色、酒店相关 **/
    /**
     * 获取角色数据
     * @note group 请求“通用角色”数据和“酒店列表”数据
     * @param { Boolean } onlyRole 是否只请求角色数据
     * @param { Boolean } isUpdate 是否局部更新数据
     */
    getRoleData(onlyRole = false, isUpdate = false) {
      let promiseObjs = [];
      const p1 = this.getGeneralRoleList();
      promiseObjs.push(p1);
      if (!onlyRole) {
        const p2 = this.getHotelList();
        promiseObjs.push(p2);
      }
      Promise.all(promiseObjs).then((res) => {
        if (!isUpdate) {
          let roleList = [],
            hotelList = [];
          if (res[0].code == 200 && res[0].data) {
            roleList = res[0].data;
            // vuex 缓存数据
            this.setGeneralRoleData(roleList);
          }
          if (!onlyRole && res[1].code == 200 && res[1].data) {
            hotelList = res[1].data;
            this.hotelList = hotelList;
          }
          this.processRoleData(roleList, hotelList);
        } else {
          if (res[0].code == 200 && res[0].data) {
            let roleList = res[0].data;
            // vuex 缓存数据
            this.setGeneralRoleData(roleList);
            this.$refs['roleTree'].updateData(roleList);
          }
        }
      }).catch((error) => {});
    },

    /**
     * 处理角色数据
     * @param { Array } roleList “通用角色”数据
     * @param { Array } hotelList “酒店列表”数据
     */
    processRoleData(roleList, hotelList) {
      if (!this.isPoolTypeDept) {
        this.$nextTick(() => {
          this.$refs['roleTree'].setData(roleList, hotelList);
        });

        if (roleList.length === 0) {
          this.setPageLoading(false);
        }
      }
    },

    /**
     * 获取“通用角色”列表
     */
    getGeneralRoleList() {
      const ft = roleAPI.roleGeneral();
      ft.then((res) => {
        if (res.code != 200 || !res.data) {
          this.setPageLoading(false);
          this.$notice.error(res.message);
        }
      }).catch((error) => {
        this.$notice.error(intl.$t("获取角色数据异常，请稍后再试"));
        console.error('Get general role error: ', error.message);
      });
      return ft;
    },

    /**
     * 获取酒店列表
     */
    getHotelList() {
      const ft = commonAPI.hotelListByName();
      ft.then().catch((error) => {
        console.error('Get hotel list error: ', error.message);
      });
      return ft;
    },

    /** 角色新增/编辑/删除 **/
    /**
     * 角色新增事件回调
     */
    handleRoleTreeNodeAddCallback() {
      this.getRoleData(true, true);
    },

    /**
     * 角色复制事件回调
     */
    handleRoleTreeNodeCopyCallback() {
      this.getRoleData(true, true);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/const";

  .tab-section {
    $height: 32px;

    height: $height;

    .tab-item {
      min-width: 136px;
      height: 100%;
      border-radius: 2px;
      border: 1px solid $theme-color;
      background-color: #FFF;
      font-size: 16px;
      color: $theme-color;
      text-align: center;
      line-height: $height;
      cursor: pointer;
      user-select: none;
    }

    .tab-item.actived {
      background-color: $theme-color;
      color: #FFF;
    }

    .tab-item + .tab-item {
      margin-left: 8px;
    }
  }

  .search-section.expand {
    height: calc(100% - 32px - 10px);
  }

  .tree-section {
    height: calc(100% - 32px*2 - 10px*2);
    margin-right: 2px;
    padding-right: 18px;
    overflow: auto;
  }
</style>
