<template>
<div class="search-pool">
    <!-- 搜素输入框 -->
    <div class="search-view">
      <el-input class="search-input" v-model="keyword" :placeholder="placeholder" maxlength="15" clearable @keydown.enter.native="handleSearchClick" @clear="handleSearchInputClear"></el-input>
      <lz-icon type="iconfont" name="lzicon-search_outline icon" :size="15" @click.native="handleSearchClick"></lz-icon>
    </div>

    <div v-if="isSearching" class="list-view">
      <template v-if="searchList && searchList.length">
        <ul>
          <li v-for="item in searchList" :key="item.type + '_' + item[props.value]" :class="['list-item pl-5 pr-5', { active: selectedValue===item[props.value] }]" @click="handleSearchItemClick(item)">
            <span>{{getListItemDesc(item)}}</span>
          </li>
        </ul>
      </template>
      <template v-else-if="searchList && searchList.length===0">
        <div class="no-data-box">
          <img src="~@/assets/images/no-data.png"/>
          <div class="text">{{$t('没有查询到你想要的结果呢～')}}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { intl } from "@tci18n/vue2";import { mapState, mapGetters } from 'vuex';
import deptAPI from '../api/dept';
import roleAPI from '../api/role';

/**
 * 组合搜索
 */
export default {
  name: 'SearchPool',
  data() {
    return {
      isSearching: false,
      // 搜索关键字
      keyword: '',
      searchKeyword: '',
      // 搜索结果
      searchList: undefined,
      // selected value
      selectedValue: this.defaultValue
    };
  },
  computed: {
    ...mapState('org', [
    'poolType',
    'activeMember']
    ),

    ...mapGetters('org', [
    'isPoolTypeDept',
    'activeDeptId',
    'activeRoleId',
    'activeMemberId']
    ),

    // input placeholder
    placeholder() {
      return this.isPoolTypeDept ? intl.$t("搜索姓名/账号名/部门名/手机号") : intl.$t("搜索角色名称");
    },

    // 数据属性配置
    props() {
      return {
        value: 'id',
        label: 'name'
      };
    },

    defaultValue() {
      return this.isPoolTypeDept ? this.activeDeptId : this.activeRoleId;
    }
  },
  watch: {
    // 监听“资源池类型”改变
    poolType(newVal, oldVal) {
      this.handleSearchInputClear();
    },

    // 监听成员操作事件
    memberAction(newVal, oldVal) {
      if (newVal) {
        this.handleMemberAction(newVal);
        this.$store.commit('org/setMemberAction', '');
      }
    }
  },
  methods: {
    /**
     * 搜索结果列表项描述
     */
    getListItemDesc(data) {
      let desc = data[this.props.label];
      if (!this.isPoolTypeDept && data.hotelVid) {
        desc = data.hotelName + ' - ' + desc;
      }
      return desc;
    },

    /**
     * 搜索事件
     */
    handleSearchClick() {
      if (!this.keyword.trim()) {
        this.handleSearchInputClear();
        return;
      } else if (this.keyword.trim() == this.searchKeyword) {
        return;
      }
      this.searchList = undefined;
      this.isSearching = true;
      this.$emit('search');
      this.selectedValue = this.defaultValue;
      if (this.isPoolTypeDept) {
        this.searchDept(this.keyword.trim());
      } else {
        this.searchRole(this.keyword.trim());
      }
    },

    /**
     * Input clear 事件
     */
    handleSearchInputClear() {
      this.keyword = '';
      this.searchKeyword = '';
      this.searchList = undefined;
      this.isSearching = false;
      this.$emit('cancel');
    },

    /**
     * 搜索列表项点击事件
     * @param { Object } data 数据项
     */
    handleSearchItemClick(data) {
      this.selectedValue = data[this.props.value];
      this.$emit('change', data);
    },


    /**
     * 搜索部门/成员
     */
    searchDept(value) {
      const params = {
        name: value,
        // 只查询部门、成员
        queryType: 0
      };
      deptAPI.deptSearch(params).then((res) => {
        if (res.code == 200 && res.data) {
          this.searchKeyword = value;
          this.searchList = res.data;
        } else {
          this.$notice.error(res.message);
        }
      }).catch((error) => {
        this.$notice.error(intl.$t("系统异常，请稍后再试"));
        console.error('Search dept error: ', error.message);
      });
    },

    /**
     * 搜索角色
     */
    searchRole(value) {
      roleAPI.roleSearch({ roleName: value }).then((res) => {
        if (res.code == 200 && res.data) {
          this.searchKeyword = value;
          this.searchList = res.data;
        } else {
          this.$notice.error(res.message);
        }
      }).catch((error) => {
        this.$notice.error(intl.$t("系统异常，请稍后再试"));
        console.error('Search role error: ', error.message);
      });
    },



    /**
     * 处理成员操作事件
     * @param { String } action 事件名称
     */
    handleMemberAction(action) {
      if (this.isSearching) {
        const index = this.searchList.findIndex((item) => item.type == 0 && item.id == this.activeMemberId);
        if (index !== -1) {
          if (action === 'delete') {
            this.searchList.splice(index, 1);
          } else if (action === 'edit') {
            this.searchList[index].name = this.activeMember.name;
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/const";
  @import "@/style/mixins";

  .search-pool {
    height: 100%;
  }

  .search-view {
    $_height: 30px;

    height: 32px;
    border: 1px solid $border-color;
    border-radius: 2px;

    .icon {
      width: $_height;
      height: $_height;
      line-height: $_height;
      color: $theme-color;
      text-align: center;
      vertical-align: middle;
      cursor: pointer;
    }

    .search-input {
      width: calc(100% - 30px);

      ::v-deep .el-input__inner {
        border: none;
        height: $_height;
        line-height: $_height;
        padding-left: 10px;
      }

      ::v-deep .el-input__icon {
        line-height: $_height;
      }
    }
  }

  .list-view {
    max-height: calc(100% - 32px - 10px);
    overflow-y: auto;
    margin-top: 10px;
    // 滚动条贴边
    margin-right: -18px;
    
    .list-item {
      height: 40px;
      line-height: 40px;
      display: block;
      cursor: pointer;

      @include no-wrap-single;
    }

    .list-item:hover {
      background-color: #EDF3FF;
    }

    .list-item.active {
      color: $theme-color;
    }

    .no-data-box {
      margin-top: 100px;
      text-align: center;

      img {
        width: 100px;
        height: 85px;
      }

      .text {
        color: #AAB1BD;
      }
    }
  }
</style>
