<template>
<div class="modal-box page-box">
    <div class="modal-body mt-10">
      <search-list class="search-section" ref="searchList" :placeholder="$t('搜索餐厅')" :props="searchProps" :showCheckbox="true" :search-list="searchList" @search="handleSearchCallback" @cancel="handleSearchCancelCallback" @check-change="handleSearchCheckChangeCallback"></search-list>

      <div v-show="!isSearchingState" class="tree-section pt-15 pb-15">
        <tree-view ref="treeView" node-key="restId" :editable="false" :deletable="false" :showCheckbox="true" :props="treeProps" @check-change="handleTreeCheckCallback"></tree-view>
      </div>
    </div>
  </div>
</template>

<script>
import SearchList from '../common/search.vue'
import TreeView from '../common/tree.vue'

export default {
  name: 'SelectRest',
  components: {
    SearchList,
    TreeView,
  },
  props: {
    restIds: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  data () {
    return {
      // search-list 组件 props
      searchProps: {
        value: 'id',
        label: 'name'
      },
      searchKeyword: '',
      // 搜索结果
      searchList: undefined,

      // tree 组件 props
      treeProps: {
        label: 'restName',
      },

      // 勾选的数据
      checkedValue: this.restIds
    }
  },
  computed: {
    // 获取组件引用
    searchListRef () {
      return this.$refs['searchList'];
    },

    treeViewRef () {
      return this.$refs['treeView'];
    }
  },
  created () {
    this.initTreeData();
  },
  methods: {
    /**
     * 设置视图 loading 状态
     */
    viewLoading (loading) {
      this.$emit('loading', loading);
    },

    /**
     * 是否处于搜索状态
     */
    isSearchingState () {
      return this.$refs['searchList']!=undefined && this.$refs['searchList'].isSearching;
    },

    /** 搜索相关 **/
    /**
     * 搜索餐厅
     * @param { String } keyword 搜素关键字
     */
    searchRest (keyword) {

    },

    /**
     * 搜索事件回调
     */
    handleSearchCallback (value) {
      if (this.searchKeyword != value) {
        this.searchList = undefined;
        this.searchRest(value);
      }
    },

    /**
     * 搜索取消事件回调
     */
    handleSearchCancelCallback () {
      this.searchList = undefined;
      this.searchKeyword = '';
    },

    /**
     * 搜索项 check 回调事件
     * @param { Object } data 数据项
     * @param { Boolean } isChecked 是否勾选
     */
    handleSearchCheckChangeCallback (data, isChecked) {
      
    },


    /** tree 相关 **/
    initTreeData () {
      this.getRestData();
    },

    /**
     * 获取餐厅数据
     */
    getRestData () {
      
    },

    /**
     * tree check 改变回调事件
     * @param { Array } data 勾选的数据
     */
    handleTreeCheckCallback (data) {
      
    },
  }
}
</script>

<style lang="scss" scoped>
.modal-box {
    height: 100%;
    overflow: hidden;
  }

  .modal-body {
    height: calc(100% - 52px - 10px);
    overflow: hidden;
    position: relative;
    margin-left: 16px;
    margin-right: 16px;

    .tree-section {
      height: calc(100% - 40px);
      overflow: auto;
    }
  }
</style>
