import { intl } from "@tci18n/vue2";
// “全选”项数据定义
const BLOC_SET_ALL_ITEM = { blocCode: '0', blocName: intl.$t("全选") };

/**
 * Role edit bloc set mixin
 */
export default {
  data() {
    return {
      // 租户关联的酒管组数据源
      blocSetDatas: [],
      blocCascadeList: []
    };
  },
  computed: {
    // “全选”项是否选中
    isBlocSetAllSelected() {
      return this.formData.blocSet.findIndex((blocCode) => blocCode == BLOC_SET_ALL_ITEM.blocCode) !== -1;
    }
  },
  created() {
    if (this.isTenantTypeBlocSetUser) {
      this.getBlocSetSelectList((data) => {
        this.blocSetDatas = data;
        this.blocSetDatas.unshift(BLOC_SET_ALL_ITEM);
      });
      this.getBlocTreeCascadeList();
    }
  },
  methods: {
    /**
     * bloc item 是否是 disabled
     */
    isBlocItemDisabled(blocCode) {
      return blocCode != BLOC_SET_ALL_ITEM.blocCode && this.formData.blocSet.findIndex((blocCode) => blocCode == BLOC_SET_ALL_ITEM.blocCode) !== -1;
    },

    /**
     * “酒管权限” select remove event
     * @param { String } value blocCode
     */
    handleBlocSetSelectRemove(value) {
      const index = this.formData.blocSet.findIndex((blocCode) => blocCode == value);
      this.formData.blocSet.splice(index, 1);
    },

    /**
     * “酒馆权限” select option click event
     * @param { String } value blocCode
     */
    handleBlocSetOptionClick(value) {
      const index = this.formData.blocSet.findIndex((blocCode) => blocCode == value);
      if (value == BLOC_SET_ALL_ITEM.blocCode) {
        if (index === -1) {
          this.formData.blocSet = [value];
        } else {
          this.formData.blocSet.splice(index, 1);
        }
      } else if (!this.isBlocItemDisabled(value)) {
        if (index === -1) {
          this.formData.blocSet.push(value);
          this.$refs['form'].validateField('blocSet');
        } else {
          this.formData.blocSet.splice(index, 1);
        }
      }
    },

    /**
     * “酒馆权限” 获取酒管权限tree数据源
     */
    getBlocTreeCascadeList() {
      this.getBlocSetCascadeList((data) => {
        this.disposeTree(data);

        this.blocCascadeList = data;
      });
    },
    // 处理酒管权限tree数据
    disposeTree(treeData) {
      // 遍历树的每一个节点
      for (let i = 0; i < treeData.length; i++) {
        let node = treeData[i];
        // 处理每个节点
        node.id = node.brandId ? node.brandId : node.blocCode;
        node.label = node.brandName ? node.brandName : node.blocName;
        node.value = node.brandCode ? node.brandCode : node.blocCode;
        if (!node.brands?.length) node.brands = null;

        // 如果当前节点有子节点，那么对子节点做同样的处理
        if (node.brands && node.brands.length > 0) {
          this.disposeTree(node.brands);
        }
      }
    }
  }
};