<template>
<div class="page-box" v-loading="loading">
    <lz-page-panel :title="title">
      <div ref="listBox" class="data-list">
        <!-- head 视图 -->
        <div ref="listHead" class="data-list__header">
          <div class="data-list__separator"></div>

          <div class="data-list__action">
            <slot name="action"></slot>
          </div>
        </div>

        <!-- body 视图 -->
        <div ref="listBody" class="data-list__body">
          <div class="data-list__table">
            <el-table ref="table" :empty-text="noDataText" row-key="userId" :data="tableDatas" :max-height="tableMaxHeight" @select="handleTableSelect" @select-all="handleTableSelectAll">
              <el-table-column type="selection" reserve-selection min-width="50"></el-table-column>
              <el-table-column prop="userName" :label="$t('姓名')" min-width="100" fixed="left"></el-table-column>
              <el-table-column prop="accountUserName" :label="$t('账号')" min-width="130"></el-table-column>
              <el-table-column :label="$t('部门')" min-width="150">
                <template slot-scope="scope">
                  <ul class="table-inline-ul">
                    <li v-for="(name, index) in popoverAppearData(scope.row.deptNames)" :key="index" :title="name">{{name}}
                    </li>
                  </ul>
                  <template v-if="scope.row.deptNames.length > 3">
                    <el-popover trigger="hover" placement="top-start" popper-class="table-popover" class="table-inline-popover">
                      <span slot="reference" class="label--ellipsis">····</span>
                      <ul class="table-inline-ul">
                        <li v-for="(name, index) in popoverHiddenData(scope.row.deptNames)" :key="index" :title="name">{{name}}
                        </li>
                      </ul>
                    </el-popover>
                  </template>
                </template>
              </el-table-column>
              <el-table-column :label="$t('角色')" min-width="120">
                <template slot-scope="scope">
                  <ul class="table-inline-ul">
                    <li v-for="(name, index) in popoverAppearData(scope.row.roleNames)" :key="index" :title="name">{{name}}</li>
                  </ul>
                  <template v-if="scope.row.roleNames.length > 3">
                    <el-popover trigger="hover" placement="top-start" popper-class="table-popover" class="table-inline-popover">
                      <label slot="reference" class="label--ellipsis">····</label>
                      <ul class="table-inline-ul">
                        <li v-for="(name, index) in popoverHiddenData(scope.row.roleNames)" :key="index" :title="name">{{name}}
                        </li>
                      </ul>
                    </el-popover>
                  </template>
                </template>
              </el-table-column>
              <el-table-column :label="$t('状态')" min-width="100">
                <template slot-scope="scope">
                  <lz-tag type="dot" :color="scope.row.state===1 ? 'green' : 'gray'">{{stateDesc(scope.row)}}</lz-tag>
                </template>
              </el-table-column>
              <el-table-column prop="createUser" :label="$t('创建人')" min-width="100"></el-table-column>
              <el-table-column :label="$t('创建时间')" min-width="100">
                <template slot-scope="scope">
                  <div>{{scope.row.gmtCreate.split(' ')[0]}}</div>
                  <div>{{scope.row.gmtCreate.split(' ')[1]}}</div>
                </template>
              </el-table-column>
              <el-table-column prop="modifyUser" :label="$t('修改人')" min-width="100"></el-table-column>
              <el-table-column :label="$t('修改时间')" min-width="100">
                <template slot-scope="scope">
                  <div>{{scope.row.gmtModified.split(' ')[0]}}</div>
                  <div>{{scope.row.gmtModified.split(' ')[1]}}</div>
                </template>
              </el-table-column>
              <el-table-column fixed="right" :label="$t('操作')" width="50">
                <template slot-scope="scope">
                  <div v-if="isUserEditPermission" class="table-operate-btn" @click="handleEditClick(scope.row)">{{$t('编辑')}}</div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>

        <!-- foot 视图 -->
        <div ref="listFoot" class="data-list__footer">
          <lz-pagination :total="pageData.total" :page-count="pageData.pageCount" :page-size="pageData.pageSize" :current-page="pageData.currentPage" @on-change="handlePaginationOnChange"></lz-pagination>
        </div>
      </div>
    </lz-page-panel>
  </div>
</template>

<script>
import { intl } from "@tci18n/vue2";import { mapState, mapGetters } from 'vuex';
import filterTableMixin from '@/common/mixin/filter-table.js';

/**
 * 成员列表公共组件
 * @module @/view/organization
 */
export default {
  name: 'MemberBox',
  mixins: [filterTableMixin],
  props: {
    // 引用源（组织架构：dept，角色：role）
    refer: {
      type: String,
      default: 'dept'
    },

    // page title
    title: String,

    loading: Boolean
  },
  data() {
    return {
      // 表格选中项数据（支持跨页勾选）
      tableSelected: []
    };
  },
  computed: {
    ...mapState('org', [
    'memberAction']
    ),

    ...mapGetters([
    'userOrgName',
    'pageButtonPermission']
    ),

    ...mapGetters('org', [
    'activeMemberId']
    ),

    // 是否具有成员编辑权限
    isUserEditPermission() {
      return this.pageButtonPermission('hrms/organization', 'editUserButton');
    }
  },
  watch: {
    // 监听成员操作事件
    memberAction(newVal, oldVal) {
      if (newVal) {
        this.handleMemberAction(newVal);
        this.$store.commit('org/setMemberAction', '');
      }
    }
  },
  methods: {
    /**
     * 状态描述
     */
    stateDesc(data) {
      return data.state == 1 ? intl.$t("启用") : intl.$t("停用") + (data.remark ? `-${data.remark}` : '');
    },

    /**
     * 获取 popover 列表外显数据
     */
    popoverAppearData(data) {
      return data.length <= 3 ? data : data.slice(0, 2);
    },

    /**
     * 获取 popover 隐藏数据
     */
    popoverHiddenData(data) {
      return data.slice(2);
    },


    /** Table select 相关 **/
    /**
     * Table 勾选数据行的 Checkbox 触发
     * @note 勾选/取消勾选都会触发
     * @param { Array } selection 已选数据项
     * @param { Object } row 选择的数据项
     */
    handleTableSelect(selection, row) {
      this.tableSelected = selection;
    },

    /**
     * 表格“全选/取消”勾选事件
     * @note 需要注意存在跨页多选的需求
     */
    handleTableSelectAll(selection) {
      this.tableSelected = selection;
    },


    /** Pagination 相关 **/
    /**
     * 分页页码改变事件
     */
    handlePaginationOnChange(curNum) {
      this.pageData.currentPage = curNum;
      this.$emit('page-change', this.pageData);
    },


    /** Table 操作相关 **/
    /**
     * Table item click
     * @param { Object } data table item data
     */
    handleEditClick(data) {
      this.$emit('edit', data);
    },


    /**
     * 成员新增处理逻辑
     */
    processAfterAdd() {
      this.resetView();
      this.$emit('page-change', this.pageData);
    },

    /**
     * 成员编辑处理逻辑
     */
    processAfterEdit() {
      const index = this.tableDatas.findIndex((item) => item.userId === this.activeMemberId);
      if (index !== -1) {
        this.$emit('page-change', this.pageData);
      }
    },

    /**
     * 成员删除处理逻辑
     */
    processAfterDelete() {
      const index = this.tableDatas.findIndex((item) => item.userId === this.activeMemberId);
      if (index !== -1) {
        this.resetView();
        this.$emit('page-change', this.pageData);
      }
    },

    /**
     * 成员状态修改处理逻辑
     * @param { Object } data 数据对象
     */
    processAfterUpdateState() {
      let item = this.tableDatas.find((item) => item.userId === this.activeMemberId);
      if (item) {
        item.state = item.state == 1 ? 0 : 1;
      }
    },


    /**
     * 处理成员操作事件
     * @param { String } action 事件名称
     */
    handleMemberAction(action) {
      switch (action) {
        case 'delete':
          this.processAfterDelete();
          break;
        case 'update-state':
          this.processAfterUpdateState();
          break;
        case 'add':
          this.processAfterAdd();
          break;
        case 'edit':
          this.processAfterEdit();
          break;
      }
    },


    /** 外部调用 **/
    /**
     * 设置组件数据
     * @param { Array } data 列表数据
     */
    setData(data) {
      this.pageData.total = data.total;
      this.pageData.pageCount = data.totalPage;
      data.datas.forEach((item) => {
        if (item.existedVirtualDept) {
          const rootName = this.userOrgName;
          item.deptNames.unshift(rootName);
        }
      });
      this.tableDatas = data.datas;

      this.noDataText = intl.$t("暂无数据");
      this.setTableMaxHeight(0, 'listBox', 'listHead', 'listFoot');
    },

    /**
     * 重置视图
     */
    resetView() {
      this.$refs['table'].clearSelection();
      this.tableSelected = [];
      this.pageData.currentPage = 1;
      this.pageData.total = 0;
      this.pageData.pageCount = 0;
      this.tableDatas = [];
    },

    /**
     * 获取分页数据
     * @returns { Object } 分页数据
     */
    getPageData() {
      return this.pageData;
    },

    /**
     * 获取已勾选的数据
     * @returns { Array } 已勾选数据
     */
    getSelectedData() {
      return this.tableSelected;
    }
  }
};
</script>

<style lang="scss">
.table-popover {
    max-width: 250px;
    padding-left: 12px;
    padding-right: 12px;

    .table-inline-ul {
      max-height: 300px;
      overflow: auto;
    }
  }
</style>

<style lang="scss" scoped>
@import "@/style/const";
  @import "@/style/mixins";

  .data-list {
    height: 100%;
    overflow: hidden;
  }

  .data-list__header {
    .data-list__separator {
      height: 1px;
      background-color: $dividing-line-color;
    }

    .data-list__action {
      height: 56px;
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  .data-list__footer {
    padding-top: 12px;
    padding-bottom: 15px;
  }

  .table-inline-popover {
    display: block;
    height: 11px;
    line-height: 11px;
  }

  .label--ellipsis {
    height: 2px;
    font-size: 16px;
    line-height: 2px;
    display: inline-block;
    vertical-align: bottom;
    cursor: help;
  }

  .table-inline-ul li {
    line-height: 1.2;
    @include no-wrap-single;
  }

  .table-inline-ul li + li {
    margin-top: 10px;
  }
</style>
