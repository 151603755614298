import { intl } from "@tci18n/vue2"; // 酒店 tree 视图节点类型（tree 视图数据引用源不同，字段上面需要做映射）
const TREE_NODE_TYPE_REGION = 'region_';
const TREE_NODE_TYPE_AREA = 'area_';
const TREE_NODE_TYPE_BRAND = 'brand_';
const TREE_NODE_TYPE_HOTEL = 'hotel_';
const TREE_NODE_TYPE_REGION_HOTEL = 'region_hotel_';
const TREE_NODE_TYPE_AREA_HOTEL = 'area_hotel_';
const TREE_NODE_TYPE_BRAND_HOTEL = 'brand_hotel_';

// 酒店 tree 筛选类型
const TREE_FILTER_TYPE_AREA = TREE_NODE_TYPE_AREA;
const TREE_FILTER_TYPE_BRAND = TREE_NODE_TYPE_BRAND;

import commonAPI from '../../../api/common';
import NodeStore from '../node-store';

/**
 * Member hotel role set hotel tree mixin
 */
export default {
  data() {
    return {
      // 筛选类型
      filterType: TREE_NODE_TYPE_REGION,
      filterTypeEnum: [
      { value: TREE_NODE_TYPE_REGION, label: intl.$t("按省市选择") }
      // { value: TREE_FILTER_TYPE_AREA, label: '按区域选择' },
      // { value: TREE_FILTER_TYPE_BRAND, label: '按品牌选择' }
      ],

      // 保存全部数据的 node 数据结构
      regionNodeStore: null,
      areaNodeStore: null,
      brandNodeStore: null,
      // tree 数据源
      regionTreeData: [],
      areaTreeData: [],
      brandTreeData: [],
      regionEmptyText: '',
      areaEmptyText: '',
      brandEmptyText: '',
      // 默认勾选节点
      regionCheckedKeys: [],
      areaCheckedKeys: [],
      brandCheckedKeys: [],

      // 已勾选城市、区域、品牌数据项
      regionCheckedItems: [],
      areaCheckedItems: [],
      brandCheckedItems: []
    };
  },
  computed: {
    // 是否是“按省市选择”
    isFilterTypeRegion() {
      return this.filterType === TREE_NODE_TYPE_REGION;
    },

    // 是否是“按区域选择”
    isFilterTypeArea() {
      return this.filterType === TREE_FILTER_TYPE_AREA;
    },

    // 是否是“按品牌选择”
    isFilterTypeBrand() {
      return this.filterType === TREE_FILTER_TYPE_BRAND;
    },

    // 当前 filterType 下对应的 tree data
    curTreeData() {
      if (this.isFilterTypeRegion) {
        return this.regionTreeData;
      } else if (this.isFilterTypeArea) {
        return this.areaTreeData;
      } else if (this.isFilterTypeBrand) {
        return this.brandTreeData;
      }
    },

    curTreeRef() {
      if (this.isFilterTypeRegion) {
        return this.$refs['regionTree'];
      } else if (this.isFilterTypeArea) {
        return this.$refs['areaTree'];
      } else if (this.isFilterTypeBrand) {
        return this.$refs['brandTree'];
      }
    },

    regionTreeRef() {
      return this.$refs['regionTree'];
    },

    areaTreeRef() {
      return this.$refs['areaTree'];
    },

    brandTreeRef() {
      return this.$refs['brandTree'];
    }
  },
  methods: {
    initTreeCheckedData() {
      this.regionCheckedItems = [...this.regionList];
      this.areaCheckedItems = [...this.areaList];
      this.brandCheckedItems = [...this.brandList];

      // 需设置 tree 视图默认勾选节点
      this.regionCheckedKeys = this.regionCheckedItems.map((code) => TREE_NODE_TYPE_REGION + code);
      this.areaCheckedKeys = this.areaCheckedItems.map((code) => TREE_NODE_TYPE_AREA + code);
      this.brandCheckedKeys = this.brandCheckedItems.map((code) => TREE_NODE_TYPE_BRAND + code);
    },

    initTreeData() {
      this.getHotelTree(TREE_NODE_TYPE_REGION);
      // this.getHotelTree(TREE_FILTER_TYPE_AREA);
      // this.getHotelTree(TREE_FILTER_TYPE_BRAND);
    },

    /**
     * 获取勾选数据源引用
     * @param { String } type 数据类型
     */
    getCheckedItems(type) {
      if (type === TREE_NODE_TYPE_REGION) {
        return this.regionCheckedItems;
      } else if (type === TREE_NODE_TYPE_AREA) {
        return this.areaCheckedItems;
      } else if (type === TREE_NODE_TYPE_BRAND) {
        return this.brandCheckedItems;
      } else if (type === TREE_NODE_TYPE_REGION_HOTEL || type === TREE_NODE_TYPE_AREA_HOTEL || type === TREE_NODE_TYPE_BRAND_HOTEL) {
        return this.hotelCheckedItems;
      }
    },

    /**
     * 获取数据类型对应的 node-store 引用
     * @param { String } type 数据类型
     */
    getNodeStore(type) {
      if (type === TREE_NODE_TYPE_REGION) {
        return this.regionNodeStore;
      } else if (type === TREE_NODE_TYPE_AREA) {
        return this.areaNodeStore;
      } else if (type === TREE_NODE_TYPE_BRAND) {
        return this.brandNodeStore;
      }
    },

    /**
     * 数据对象类型是否是 hotel
     * @param { String } type 数据类型
     * @returns { Boolean } true/false
     */
    isDataTypeHotel(type) {
      return type === TREE_NODE_TYPE_REGION_HOTEL || type === TREE_NODE_TYPE_AREA_HOTEL || type === TREE_NODE_TYPE_BRAND_HOTEL;
    },


    /**
     * 酒店树筛选类型切换事件
     * @param { String } type 类型
     */
    handleFilterItemClick(type) {
      if (this.filterType !== type) {
        this.filterType = type;
      }
    },

    /**
     * “选择全部/取消选中”点击事件
     * @param { Boolean } checked 是否是勾选操作
     */
    handleTreeCheckAllClick(checked) {
      this.curTreeData.forEach((item) => {
        if (!item.disabled) {
          // 需注意 check 状态改变，会触发已渲染节点的 “check-change” 事件
          this.curTreeRef.setChecked(item, checked);
          this.diffHotelPropsItemChecked(item, checked, item.type);
        }
        let node = this.curTreeRef.getNode(item.id);
        this.recursiveDownSetNodeChecked(node, checked);
      });
      if (this.curTreeData.length) {
        this.processCategoryCheckNotice(this.curTreeData[0].type, checked);
      }
    },


    /**
     * 获取酒店 tree 数据
     * @param { String } type 筛选类型
     */
    getHotelTree(type) {
      let service, nodeType;
      if (type === TREE_NODE_TYPE_REGION) {
        service = commonAPI.hotelTreeByRegion;
        nodeType = TREE_NODE_TYPE_REGION;
      }

      service().then((res) => {
        if (res.code == 200 && res.data) {
          this.processHotelTreeData(res.data, nodeType);
        }
      }).catch((error) => {
        this.$notice.error(intl.$t("系统异常，请稍后再试"));
        console.error(`Get ${type} hotel tree error: `, error.message);
      });
    },

    /**
     * hote tree 数据处理
     * @note
     * - 初始只渲染第一层级，其他层级异步加载
     * - 没有“城市”、“区域”、“品牌”权限的节点显示但状态为 dsiabled，其下的“酒店”为当前登录人拥有权限的酒店，节点状态均为 enabled
     * 
     * @param { Object } data 原始数据
     * @param { String } nodeType 酒店树类型
     */
    processHotelTreeData(data, nodeType) {
      let treeData = [];
      data.forEach((item) => {
        let nodeData = {
          id: nodeType + item.code,
          code: item.code,
          name: item.name,
          type: nodeType,
          isLeaf: !item.children.length && !item.hotels.length,
          disabled: !item.enabled,
          hotels: item.hotels,
          child: item.children
        };
        treeData.push(nodeData);
      });
      if (nodeType === TREE_NODE_TYPE_REGION) {
        this.regionTreeData = treeData;
        this.regionNodeStore = new NodeStore({ data, categoryKey: nodeType, hotelKey: TREE_NODE_TYPE_HOTEL });
      } else if (nodeType === TREE_NODE_TYPE_AREA) {
        this.areaTreeData = treeData;
        this.areaNodeStore = new NodeStore({ data, categoryKey: nodeType, hotelKey: TREE_NODE_TYPE_HOTEL });
      } else if (nodeType === TREE_NODE_TYPE_BRAND) {
        this.brandTreeData = treeData;
        this.brandNodeStore = new NodeStore({ data, categoryKey: nodeType, hotelKey: TREE_NODE_TYPE_HOTEL });
      }
    },

    /**
     * 处理 tree 子节点数据
     * @note 数据源中 “enabled” 字段用于判断是否具有节点权限，无权限处理为 “disabled” 状态
     * @param { Object } data 当前节点数据对象
     * @param { Function } resolve 回调函数
     */
    processHotelTreeChildData(data, resolve) {
      let childData = [];
      data.child.forEach((item) => {
        let nodeData = {
          id: data.type + item.code,
          code: item.code,
          name: item.name,
          type: data.type,
          isLeaf: !item.children.length && !item.hotels.length,
          disabled: !item.enabled,
          hotels: item.hotels,
          child: item.children
        };
        childData.push(nodeData);
      });
      data.hotels.forEach((item) => {
        let nodeData = {
          id: TREE_NODE_TYPE_HOTEL + item.hotelVid,
          code: item.hotelVid,
          name: item.hotelName,
          regionId: item.regionId,
          areaId: item.areaId,
          brandCode: item.brandCode,
          isLeaf: true,
          disabled: false
        };
        if (data.type === TREE_NODE_TYPE_REGION) {
          nodeData.type = TREE_NODE_TYPE_REGION_HOTEL;
        } else if (data.type === TREE_NODE_TYPE_AREA) {
          nodeData.type = TREE_NODE_TYPE_AREA_HOTEL;
        } else if (data.type === TREE_NODE_TYPE_BRAND) {
          nodeData.type = TREE_NODE_TYPE_BRAND_HOTEL;
        }
        childData.push(nodeData);
      });
      resolve(childData);
    },


    /**
     * 树视图渲染
     */
    renderTreeContent(h, { node, data, store }) {
      node.isLeaf = data.isLeaf;
      return h('div', {
        class: `tree-node__title ${node.level === 1 ? 'top-level' : ''}`
      }, data.name);
    },

    /**
     * 节点展开事件
     * @note 如果子节点数据未获取，则同时获取子节点数据并追加
     * @note 新渲染数据需要同步节点勾选状态
     * @param { Object } data 节点数据对象
     * @param { Object } node 节点对象
     * @param { Object } nodeEle 节点组件
     */
    handleTreeNodeExpand(data, node, nodeEle) {
      if (!node.childNodes.length) {
        this.processHotelTreeChildData(data, (children) => {
          this.curTreeRef.updateKeyChildren(node.key, children);

          this.$nextTick(() => {
            children.forEach((item) => {
              let index;
              let checkedItems = this.getCheckedItems(item.type);
              if (this.isDataTypeHotel(item.type)) {
                index = checkedItems.findIndex((checkedItem) => checkedItem.code == item.code);
              } else {
                index = checkedItems.findIndex((code) => code == item.code);
              }
              this.setTreeNodeCheckState(item.id, index !== -1, this.curTreeRef);
            });
          });
        });
      }
    },

    /**
     * 树视图节点勾选改变事件
     * @note 勾选逻辑：勾父既勾子，勾子不勾父，去父既去子，去子既去父
     * @note 业务逻辑
     * - 节点加载、勾选逻辑
     * - 同步勾选数据到 hotelCheckedItems
     * - 同步城市、区域、品牌勾选状态到 regionCheckedItems、areaCheckedItems、brandCheckedItems
     * - 同步 "兄弟" 树节点勾选状态
     * @note 调用逻辑
     * - el-tree 回调
     */
    handleTreeNodeCheck(data, { checkedNodes, checkedKeys, halfCheckedNodes, halfCheckedKeys }) {
      let node = this.curTreeRef.getNode(data.id);
      this.recursiveSetNodeChecked(node);
      this.processCategoryCheckNotice(data.type, node.checked);
    },

    /**
     * 获取兄弟 tree 引用
     * @param { String } type 数据类型
     * @returns { Array } tree 引用
     */
    getSiblingTreeRef(type) {
      let refs = [];
      if (type === TREE_NODE_TYPE_REGION_HOTEL) {
        refs.push(
          { treeRef: this.areaTreeRef, treeType: TREE_NODE_TYPE_AREA },
          { treeRef: this.brandTreeRef, treeType: TREE_NODE_TYPE_BRAND }
        );
      } else if (type === TREE_NODE_TYPE_AREA_HOTEL) {
        refs.push(
          { treeRef: this.regionTreeRef, treeType: TREE_NODE_TYPE_REGION },
          { treeRef: this.brandTreeRef, treeType: TREE_NODE_TYPE_BRAND }
        );
      } else if (type === TREE_NODE_TYPE_BRAND_HOTEL) {
        refs.push(
          { treeRef: this.regionTreeRef, treeType: TREE_NODE_TYPE_REGION },
          { treeRef: this.areaTreeRef, treeType: TREE_NODE_TYPE_AREA }
        );
      }
      return refs;
    },

    /**
     * 处理“已选”酒店列表更改逻辑，更新树勾选状态
     * @note
     * - 增加数据项后，同步节点勾选状态
     * - 移除数据项后，同步取消节点勾选状态
     * @param { String } key 要更新的数据节点 key
     * @param { Boolean } checked 勾选状态
     */
    setTreeNodeCheckState(key, checked, treeRef) {
      let node = treeRef.getNode(key);
      if (node) {
        node.setChecked(checked);
      }
    },

    /**
     * 处理“已选”酒店列表更改逻辑，更新“兄弟”树勾选状态（递归遍历）
     * @note
     * - tree 是异步渲染，存在无法获取 tree 上的节点的情况
     * - 遍历已渲染的节点，使用 tree 数据结构（如果节点已渲染，父节点也是已渲染状态）
     * - 遍历未渲染的节点，使用新数据结构 node-store
     */
    setTreeNodeCheckStateTraversal(key, checked, treeRef, treeType) {
      let node = treeRef.getNode(key);
      if (node) {
        node.setChecked(checked);
        if (node.level !== 1) {
          this.recursiveUpSetNodeChecked(node, checked, treeRef);
        }
      } else {
        let node = this.getNodeStore(treeType).getNode(key);
        if (node && node.level !== 1) {
          this.recursiveSiblingUpSetNodeChecked(node, checked, treeRef, treeType);
        }
      }
    },

    /**
     * 递归遍历设置树节点 check 状态
     * @note 酒店节点 check 状态变更
     * - 同步已选“酒店角色”列表
     * - 同步“兄弟 tree”对应酒店勾选状态
     * 
     * @note 非酒店节点 check 状态变更
     * - 同步“城市”、“区域”、“品牌”节点勾选状态
     * 
     * @note 向上层递归遍历设置勾选状态
     * - 同步“城市”、“区域”、“品牌”节点勾选状态
     * - 查找“兄弟”树，以相同逻辑处理对应酒店勾选状态变更
     */
    recursiveSetNodeChecked(node) {
      if (this.isDataTypeHotel(node.data.type)) {
        this.syncHotelItemCheckState(node.data, node.checked);

        let treeRefs = this.getSiblingTreeRef(node.data.type);
        treeRefs.forEach((item) => {
          if (node.checked) {
            this.setTreeNodeCheckState(node.data.id, node.checked, item.treeRef);
          } else {
            this.setTreeNodeCheckStateTraversal(node.data.id, node.checked, item.treeRef, item.treeType);
          }
        });
      } else {
        this.diffHotelPropsItemChecked(node.data, node.checked, node.data.type);
      }
      if (!node.isLeaf) {
        this.recursiveDownSetNodeChecked(node, node.checked);
      }

      if (node.level !== 1) {
        this.recursiveUpSetNodeChecked(node, node.checked, this.curTreeRef);
      }
    },

    /**
     * 向下递归遍历设置树节点 check 状态
     * @note
     * - 如果子节点未加载，先加载子节点，再向下递归遍历
     * - 状态为 disabled 的节点，不可变更勾选状态
     * 
     * @param { Object } node 节点对象
     * @param { Boolean } checked 是否勾选
     */
    recursiveDownSetNodeChecked(node, checked) {
      if (!node.childNodes.length) {
        this.processHotelTreeChildData(node.data, (children) => {
          this.curTreeRef.updateKeyChildren(node.key, children);
        });
      }

      this.$nextTick(() => {
        node.childNodes.forEach((childNode) => {
          if (!childNode.data.disabled) {
            this.curTreeRef.setChecked(childNode.key, checked);

            if (this.isDataTypeHotel(childNode.data.type)) {
              this.syncHotelItemCheckState(childNode.data, checked);

              let treeRefs = this.getSiblingTreeRef(childNode.data.type);
              treeRefs.forEach((item) => {
                if (checked) {
                  this.setTreeNodeCheckState(childNode.data.id, checked, item.treeRef);
                } else {
                  this.setTreeNodeCheckStateTraversal(childNode.data.id, checked, item.treeRef, item.treeType);
                }
              });
            } else {
              this.diffHotelPropsItemChecked(childNode.data, checked, childNode.data.type);
            }
          }
          if (!childNode.data.isLeaf) {
            this.recursiveDownSetNodeChecked(childNode, checked);
          }
        });
      });
    },

    /**
     * 向上层递归遍历设置树节点 check 状态（tree node 数据结构）
     * @note
     * - 父级不会是“酒店”节点
     * - 当前只处理“取消勾选”逻辑
     */
    recursiveUpSetNodeChecked(node, checked, treeRef) {
      let parentNode = node.parent;
      if (!checked && !parentNode.data.disabled) {
        treeRef.setChecked(parentNode.key, checked);

        this.diffHotelPropsItemChecked(parentNode.data, checked, parentNode.data.type);
      }
      if (!checked && parentNode.level !== 1) {
        this.recursiveUpSetNodeChecked(parentNode, checked, treeRef);
      }
    },

    /**
     * 向上层递归遍历设置树节点 check 状态（自定义数据结构）
     * @note
     * - 父级不会是“酒店”节点
     * - 当前只处理“取消勾选”逻辑
     * - 通过 node-store 数据结构查找处理
     */
    recursiveSiblingUpSetNodeChecked(node, checked, treeRef, treeType) {
      let parentNode = node.parent;
      if (!checked && !parentNode.disabled) {
        this.setTreeNodeCheckState(parentNode.key, checked, treeRef);

        this.diffHotelPropsItemChecked(parentNode.data, checked, treeType);
      }
      if (!checked && parentNode.level !== 1) {
        this.recursiveSiblingUpSetNodeChecked(parentNode, checked, treeRef, treeType);
      }
    },


    /**
     * 同步 tree hotel 节点勾选状态
     * @param { Number|String } code hotel code
     * @param { Boolean } checked true/false
     */
    syncTreeHotelNodeCheckState(code, checked) {
      if (checked) {
        this.setTreeNodeCheckState(TREE_NODE_TYPE_HOTEL + code, checked, this.regionTreeRef);
        this.setTreeNodeCheckState(TREE_NODE_TYPE_HOTEL + code, checked, this.areaTreeRef);
        this.setTreeNodeCheckState(TREE_NODE_TYPE_HOTEL + code, checked, this.brandTreeRef);
      } else {
        this.setTreeNodeCheckStateTraversal(TREE_NODE_TYPE_HOTEL + code, checked, this.regionTreeRef, TREE_NODE_TYPE_REGION);
        this.setTreeNodeCheckStateTraversal(TREE_NODE_TYPE_HOTEL + code, checked, this.areaTreeRef, TREE_NODE_TYPE_AREA);
        this.setTreeNodeCheckStateTraversal(TREE_NODE_TYPE_HOTEL + code, checked, this.brandTreeRef, TREE_NODE_TYPE_BRAND);
      }
    },

    /**
     * 同步城市、区域、品牌勾选状态更改
     * @param { Object } data 数据项
     * @param { Boolean } checked true/false
     * @param { String } type tree node type
     */
    diffHotelPropsItemChecked(data, checked, type) {
      let checkedItems = this.getCheckedItems(type);
      let index = checkedItems.findIndex((code) => code == data.code);
      if (checked && index === -1) {
        checkedItems.push(data.code);
      } else if (!checked && index !== -1) {
        checkedItems.splice(index, 1);
      }
    },


    /**
     * 非酒店节点勾选，提示逻辑（提示一次）
     * @param { String } type tree node type
     * @param { Boolean } checked 是否勾选
     */
    processCategoryCheckNotice(type, checked) {
      if (checked && !this.isDataTypeHotel(type) && this.notifiable) {
        this.$notice.success(intl.$t("选择地域、品牌、省市，后续如此范围内新增酒店时，会自动勾选分配给该用户"));
        this.$emit('notice');
      }
    }
  }
};