<template>
<div class="page-box">
    <tree-head refer="dept" :rootData="rootData" :addable="isDeptAddPermission" @current-change="handleRootNodeCurrentChange" @add="handleRootNodeAdd"></tree-head>

    <!-- 树视图组件 -->
    <tree-view ref="treeView" :node-key="nodeKey" :editable="true" :deletable="true" :custom-title="deptCustomTitle" :action-btn-enable="deptActionBtnEnable" :props="treeProps" :data="treeData" @current-change="handleTreeCurrentChangeCallback" @add="handleAddTreeNodeCallback" @edit="handleEditTreeNodeCallback" @delete="handleDeleteTreeNodeCallback"></tree-view>


    <!-- 部门新增/编辑 -->
    <lz-drawer v-model="showDeptDrawer" name="dept-edit" :mask-closable="false" loading-enable :loading="drawerLoading" :title="drawerDeptConfig.title" @close="drawerLoading = false">
      <dept-edit v-if="showDeptDrawer" v-model="showDeptDrawer" :action="drawerDeptConfig.action" :data="drawerDeptConfig.data" :hotelList="hotelList" @loading="(loading) => { drawerLoading = loading }" @save="handleSaveDeptCallback" @delete="handleDeleteDeptCallback"></dept-edit>
    </lz-drawer>

    <!-- 删除 -->
    <lz-dialog v-model="showDialog" name="delete-dept" width="360px" :title="dialogConfig.title" :confirm-button-text="$t('确认')" :mask-closable="!dialogLoading" loading-enable :loading="dialogLoading" @confirm="handleDeleteDialogConfirm()"></lz-dialog>
  </div>
</template>

<script>
import { intl } from "@tci18n/vue2";import { mapState, mapGetters, mapMutations } from 'vuex';
import TreeView from '../common/tree.vue';
import TreeHead from './treeHead.vue';
import DeptEdit from '../dept/edit.vue';
import deptAPI from '../api/dept';

/**
 * 部门树视图
 * @module @/view/organization
 */
export default {
  name: 'DeptTree',
  components: {
    TreeView,
    TreeHead,
    DeptEdit
  },
  props: {
    // tree node key
    nodeKey: {
      type: String,
      default: ''
    },

    // 根节点数据
    rootData: {
      type: Object
    },

    // 酒店列表
    hotelList: Array
  },
  data() {
    return {
      // tree 原始数据
      data: null,
      // tree 组件 props
      treeProps: {
        label: 'deptName',
        rowHeight: 40
      },

      drawerLoading: false,
      // 组织架构编辑 drawer
      showDeptDrawer: false,
      drawerDeptConfig: {
        title: '',
        // 操作类型（1-新增部门，2-编辑部门）
        action: 1,
        data: null
      },

      dialogLoading: false,
      showDialog: false,
      dialogConfig: {
        title: intl.$t("是否确认删除？"),
        data: null
      }
    };
  },
  computed: {
    ...mapState('org', [
    'activitedDept']
    ),

    ...mapGetters([
    'pageButtonPermission']
    ),

    ...mapGetters('org', [
    'activeDeptId']
    ),

    // root 节点是否是 disabled 状态
    rootDisbaled() {
      return !(this.rootData && this.rootData.permission);
    },

    // 树视图数据源
    treeData() {
      return this.data && this.data[0].children || [];
    },

    // 是否具有部门“编辑”权限
    isDeptEditPermission() {
      return this.pageButtonPermission('hrms/organization', 'updateDeptButton');
    },

    // 是否具有部门“新增”权限
    isDeptAddPermission() {
      return this.pageButtonPermission('hrms/organization', 'addDeptButton');
    },

    // 是否具有部门“删除”权限
    isDeptDeletePermission() {
      return this.pageButtonPermission('hrms/organization', 'deleteDeptButton');
    },

    // 组件引用
    treeViewRef() {
      return this.$refs['treeView'];
    }
  },
  methods: {
    ...mapMutations('org', [
    'setActiveDept',
    'setPageAction']
    ),

    /** Root 节点相关 **/
    /**
     * root 节点选择事件
     */
    handleRootNodeCurrentChange() {
      this.handleTreeCurrentChangeCallback(this.rootData, true);
    },

    /**
     * root 节点新增事件
     */
    handleRootNodeAdd() {
      this.handleAddTreeNodeCallback(this.rootData);
    },


    /** 树视图相关 **/
    /**
     * 部门自定义名称
     * @param { Object } data 部门数据
     * @returns { String } 自定义名称
     */
    deptCustomTitle(data) {
      return intl.$t(`{slot0}（{slot1}人）`, { "slot0": data.deptName, "slot1": data.userNum });
    },

    /**
     * 部门操作按钮是否有权限
     * @returns { Boolean } true/false
     */
    deptActionBtnEnable(action) {
      switch (action) {
        case 'edit':
          return this.isDeptEditPermission;
          break;
        case 'add':
          return this.isDeptAddPermission;
          break;
        case 'delete':
          return this.isDeptDeletePermission;
          break;

        default:
          return true;
          break;
      }
    },

    /** 部门新增/编辑 **/
    /**
     * tree 新增节点事件
     * @param { Object|Null } data 节点对象数据
     */
    handleAddTreeNodeCallback(data) {
      this.drawerDeptConfig.title = intl.$t("新增部门");
      this.drawerDeptConfig.action = 1;
      this.drawerDeptConfig.data = {
        deptId: '',
        deptName: '',
        deptPid: data && data.deptId !== undefined && data.deptId !== '' ? data.deptId : '',
        deptPName: data && data.deptName || ''
      };
      this.showDeptDrawer = true;
    },

    /**
     * tree 编辑节点事件
     * @param { Object } data 节点对象数据
     * @param { Object|Null } parentData 父节点对象
     */
    handleEditTreeNodeCallback(data, parentData) {
      const pData = parentData || this.rootData;
      this.drawerDeptConfig.title = data.deptName;
      this.drawerDeptConfig.action = 2;
      this.drawerDeptConfig.data = {
        deptId: data.deptId,
        deptName: data.deptName,
        deptPid: data.deptPid,
        deptPName: pData.deptName,
        // 是否具有上级部门查看权限
        pPermission: data.deptPid == pData.deptId,
        hotelVid: data.hotelVid || ''
      };
      this.showDeptDrawer = true;
    },

    /**
     * 部门新增/编辑保存回调事件
     * @params { Object } data 表单数据
     */
    handleSaveDeptCallback(data) {
      // 刷新组织架构树
      this.$emit(this.drawerDeptConfig.action === 2 ? 'edit' : 'add');
    },


    /** 部门删除 **/
    /**
     * 删除部门
     */
    deleteDept(deptId) {
      this.dialogLoading = true;
      deptAPI.deptDelete({ id: deptId }).then((res) => {
        this.dialogLoading = false;
        if (res.code == 200) {
          this.$notice.success(intl.$t("删除部门成功"));

          this.processAfterDeleteDept(deptId);
        } else {
          this.$notice.error(res.message);
        }
      }).catch((error) => {
        this.dialogLoading = false;
        this.$notice.error(intl.$t("系统异常，请稍后再试"));
        console.error('Delete dept error: ', error.message);
      });
    },

    /**
     * 删除部门成功处理逻辑
     * @note
     * - 刷新树视图数据
     * - 如果删除节点也同时为当前选中的节点，更新选中节点为默认值（即未选中节点）
     * - 如果删除节点在 defaultExpandedKeys 中，则删除对应值
     * @param { String|Number } deptId 部门 Id
     */
    processAfterDeleteDept(deptId) {
      this.showDialog = false;
      this.showDeptDrawer = false;
      this.treeViewRef.deleteData(deptId);

      this.$emit('delete');
    },

    /**
     * 删除 dialog 回调事件
     */
    handleDeleteDialogConfirm() {
      this.deleteDept(this.dialogConfig.data);
    },

    /**
     * tree 删除节点事件
     * @param { Object } data 节点对象数据
     */
    handleDeleteTreeNodeCallback(data) {
      this.dialogConfig.data = data.deptId;
      this.showDialog = true;
    },

    /**
     * resource-edit 部门删除回调事件
     * @param { String|Numerb } deptId 部门 id
     */
    handleDeleteDeptCallback(deptId) {
      this.dialogConfig.data = deptId;
      this.showDialog = true;
    },



    /** 部门选择 **/
    /**
     * tree 节点选择事件
     * @param { Object } data 当前节点数据
     * @param { Boolean } isRoot 是否是 root 节点
     */
    handleTreeCurrentChangeCallback(data, isRoot) {
      if (data.deptId != this.activeDeptId) {
        this.setActiveDept(data);
      }
      if (isRoot) {
        // root 节点点击，取消 tree 节点选中
        this.treeViewRef.setCurrentKey(null);
      }
      this.$emit('current-change', data);
    },



    /** 外部调用 **/
    /**
     * 设置组件数据
     * @param { Array } data 树视图数据
     */
    setData(data) {
      this.data = data;

      // 默认选中：具有 root 节点权限则选中此节点，否则选中第一个子节点
      const treeData = data[0].children;
      const activeData = this.rootDisbaled ? treeData[0] : data[0];
      this.treeViewRef.setData(treeData);
      if (this.rootDisbaled) {
        this.treeViewRef.setCurrentKey(activeData.deptId);
      }
      this.setActiveDept(activeData);
    },

    /**
     * 处理页面事件
     * @note 新建部门（首页-快捷入口）
     * @param { String } action 操作事件
     */
    setDeptPageAction(action) {
      if (action === 'create-dept') {
        this.handleAddTreeNodeCallback();
        this.setPageAction('');
      }
    },

    /**
     * 更新组件数据
     * @param { Array } data 树视图数据
     */
    updateData(data) {
      this.data = data;
      const treeData = data[0].children;
      this.treeViewRef.updateData(treeData);
      if (this.activeDeptId && this.activeDeptId != data[0].deptId) {
        this.treeViewRef.setCurrentKey(this.activeDeptId);
      }
    },

    /**
     * 删除节点数据
     * @note 由于 tree 数据会刷新，这里无需主动删除节点
     * @param { String|Number } deptId 部门 id
     */
    deleteData(deptId) {
      this.treeViewRef.deleteData(deptId);
      this.setActiveDept(null);
    },

    /**
     * 设置部门节点选中
     * @param { Object } data 部门数据
     */
    selectData(data) {
      if (data.id != this.activeDeptId) {
        this.setActiveDept({ deptId: data.id, deptName: data.name });
        this.treeViewRef.setCurrentKey(this.activeDeptId);
      }
    }
  }
};
</script>

<style lang="scss" src="../common/tree.scss" scoped>

</style>
