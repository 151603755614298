<template>
<div class="modal-box page-box">
    <div class="modal-body mt-10">
      <search-list class="search-section" ref="searchList" :placeholder="$t('搜索成员姓名/手机号')" :props="searchProps" :showCheckbox="true" :search-list="searchList" @search="handleSearchCallback" @cancel="handleSearchCancelCallback" @check-change="handleSearchCheckChangeCallback">
        <template slot="widget">
          <div class="filter-widget">
            <div :class="['filter-item', { active: isFilterTypeAll }]" @click="handleFilterItemClick('all')">{{$t('所有成员')}}</div>
            <div :class="['filter-item', { active: !isFilterTypeAll }]" @click="handleFilterItemClick('part')">{{$t('已选成员(')}}{{checkedValue.length}})</div>
          </div>
        </template>
      </search-list>

      <div v-show="!isSearchingState()" class="tree-section pt-15 pb-15">
        <!-- tree 视图 -->
        <template>
          <el-tree v-show="isFilterTypeAll" class="tree-view" ref="tree" node-key="deptId" :highlight-current="false" :empty-text="$t('暂无数据')" :data="treeData" :default-expanded-keys="defaultExpandedKeys" :render-content="renderTreeContent" @node-expand="handleTreeNodeExpand"></el-tree>
        </template>

        <!-- 已选成员列表 -->
        <template v-if="!isFilterTypeAll">
          <ul class="check-list">
            <div v-if="checkedList.length > 0" class="mb-5">
              <el-checkbox :value="checkAll" :indeterminate="indeterminate" @click.prevent.native="handleCheckAll">
                <span class="level-1">{{activeRole.roleName}}</span>
              </el-checkbox>
            </div>

            <li v-for="(item, index) in checkedList" :key="index" class="list-item">
              <el-checkbox v-model="item.checked" @change="checked => handleCheckboxChangeCallback(item, checked)">
                <span>{{item.name}}</span>
              </el-checkbox>
            </li>
          </ul>
        </template>
      </div>
    </div>

    <div class="lz-modal__footer lz-modal__footer__line flex_shrink__0">
      <el-button type="primary" size="small" class="lz-modal__footer__button confirm" @click="handleSaveClick">{{$t('确定')}}</el-button>
      <el-button size="small" class="lz-modal__footer__button cancel" @click="handleCloseClick">{{$t('取消')}}</el-button>
    </div>
  </div>
</template>

<script>
import { intl } from "@tci18n/vue2";import { mapState, mapGetters } from 'vuex';
import SearchList from '../common/search.vue';
import memberAPI from '../api/member';

/**
 * 添加成员
 * @note 部门和成员是异步请求加载，因为部门下可能会存在多级子部门需要请求数据，所以能做到只勾选“成员”，不能勾选部门
 * @module @/view/organization
 */
export default {
  name: 'SelectMember',
  components: {
    SearchList
  },
  data() {
    return {
      // search-list 组件 props
      searchProps: {
        value: 'id',
        label: 'name'
      },
      searchKeyword: '',
      // 搜索结果
      searchList: undefined,

      // tree 视图数据
      treeData: [],
      // 默认展开节点 keys
      defaultExpandedKeys: [],
      // 缓存 tree 节点下成员数据对象（key：member_+id）
      treeMember: {},

      // 查看类型（1-全部成员，2-已选成员）
      filterType: 'all',

      // 已选视图，是否勾选全部 checkbox
      checkAll: true,
      // 已选视图，是否是半选状态
      indeterminate: false,

      // 勾选的数据
      checkedList: []
    };
  },
  computed: {
    ...mapState('org', [
    'deptTreeData',
    'activeRole']
    ),

    ...mapGetters('org', [
    'activeRoleId',
    'isTreeRootNode']
    ),

    /**
     * 勾选数据的值
     */
    checkedValue() {
      return this.checkedList.filter((item) => item.checked).map((item) => item.id);
    },

    // 查看类型是否是“全部成员”
    isFilterTypeAll() {
      return this.filterType === 'all';
    },

    // tree root 节点是否是 disabled 状态
    rootDisbaled() {
      return this.treeData.length === 0 || !this.treeData[0].permission;
    },

    // 获取组件引用
    searchListRef() {
      return this.$refs['searchList'];
    },

    treeRef() {
      return this.$refs['tree'];
    }
  },
  created() {
    this.getMemberListChecked();
  },
  methods: {
    /**
     * 设置视图 loading 状态
     */
    viewLoading(loading) {
      this.$emit('loading', loading);
    },

    /**
     * 是否处于搜索状态
     */
    isSearchingState() {
      return this.$refs['searchList'] != undefined && this.$refs['searchList'].isSearching;
    },


    /** 搜索相关 **/
    /**
     * 搜索成员
     * @param { String } keyword 搜素关键字
     */
    searchMember(keyword) {
      const params = {
        name: keyword,
        roleId: this.activeRoleId,
        // 暂不使用此字段
        queryType: 0
      };
      this.viewLoading(true);
      memberAPI.memberSearch(params).then((res) => {
        this.viewLoading(false);
        if (res.code == 200 && res.data) {
          this.searchKeyword = keyword;
          this.searchList = res.data;

          this.searchListRef.setChecked(this.checkedValue);
        } else {
          this.$notice.error(res.message);
        }
      }).catch((error) => {
        this.viewLoading(false);
        this.$notice.error(intl.$t("系统异常，请稍后再试"));
        console.error('Search member error: ', error.message);
      });
    },

    /**
     * 搜索事件回调
     */
    handleSearchCallback(value) {
      if (this.searchKeyword != value) {
        this.searchList = undefined;
        this.searchMember(value);
      }
    },

    /**
     * 搜索取消事件回调
     */
    handleSearchCancelCallback() {
      this.searchList = undefined;
      this.searchKeyword = '';
    },

    /**
     * 搜索项 check 回调事件
     * @param { Object } data 数据项
     * @param { Boolean } isChecked 是否勾选
     */
    handleSearchCheckChangeCallback(data, isChecked) {
      this.syncCheckedState(data, isChecked);
      // 同步 tree 组件 checked
      this.setTreeNodeChecked(data.id, isChecked);
    },

    /**
     * 查看类型切换事件
     * @param { Stirng } type 查看类型
     */
    handleFilterItemClick(type) {
      if (this.filterType !== type) {
        this.filterType = type;

        if (!this.isFilterTypeAll) {
          this.searchListRef.setIsSearching(false);
        } else if (this.isFilterTypeAll && this.searchKeyword) {
          this.searchListRef.setIsSearching(true);
        }
      }
    },


    /** tree 相关 **/
    initTreeData() {
      // 当前会更改树节点数据，做防数据污染处理
      let tmpData = JSON.stringify(this.deptTreeData);
      this.treeData = JSON.parse(tmpData);
      if (this.treeData.length) {
        // 数字0作为 node-key 存在问题，转换为字符串
        this.treeData[0].deptId += '';

        // 默认需要展开一级节点
        let rootDeptId = this.treeData[0].deptId;
        this.defaultExpandedKeys.push(rootDeptId);

        // 如果有根节点权限需要获取成员
        if (!this.rootDisbaled) {
          this.getDeptMemberList(rootDeptId);
        }
      }
    },

    /**
     * 获取部门下成员列表
     * @param { String } deptId 部门Id
     */
    getDeptMemberList(deptId) {
      let params = {
        deptId,
        roleId: this.activeRoleId
      };
      memberAPI.memberListByDept(params).then((res) => {
        if (res.code == 200 && res.data) {
          this.processDeptMemberList(res.data, deptId);
        }
      }).catch((error) => {
        console.error('Get member list by dept error: ', error.message);
      });
    },

    /**
     * 部门成员处理
     * @note 数据转换与缓存
     * - tree 使用 deptId 为 node-key 名，需对成员数据对象构造 deptId
     * - 成员可存在于多个部门节点，deptId 需拼接 dept_ + deptId，避免 node-key 不唯一
     * - 以 member_+id 缓存成员数据对象，以同步 checked 状态
     * @param { Array } data 成员列表
     * @param { String } deptId 部门Id
     */
    processDeptMemberList(data, deptId) {
      const node = this.treeRef.getNode(deptId);
      data.forEach((item) => {
        const member = {
          ...item,
          isMember: true,
          checked: this.checkedValue.findIndex((checkedValue) => checkedValue == item.id) !== -1,
          deptId: 'dept_' + deptId + '_member_' + item.id
        };
        if (node.childNodes.length) {
          this.treeRef.insertBefore(member, node.childNodes[0].key);
        } else {
          this.treeRef.append(member, deptId);
        }

        // 缓存成员数据对象，用于同步数据勾选状态
        const key = 'member_' + item.id;
        if (this.treeMember[key]) {
          this.treeMember[key].push(member);
        } else {
          this.treeMember[key] = [member];
        }
      });
      this.treeRef.getNode(deptId).data.userFetch = true;
    },

    /**
     * 树视图渲染
     */
    renderTreeContent(h, { node, data, store }) {
      if (!this.isNodeMember(data)) {
        node.isLeaf = false;
      }

      let rowElements = [];
      let prefixElments = [];
      if (!this.isNodeMember(data)) {
        prefixElments = [
        h('span', {
          class: 'tree-node__title'
        }, data.deptName)];

      } else {
        prefixElments = [
        h('el-checkbox', {
          props: {
            value: data.checked
          },
          on: {
            'change': (checked) => {
              this.handleTreeNodeCheck(data, checked);
            }
          }
        }, data.name)];

      }
      rowElements.push(
        h('div', {
          class: 'tree-row__prefix display__flex align_items__center'
        }, prefixElments)
      );
      return h('div', {
        class: 'tree-row'
      }, rowElements);
    },

    /**
     * 节点是否是“成员”
     * @returns { Boolean } true/false
     */
    isNodeMember(data) {
      return data.isMember || false;
    },

    /**
     * 节点是否已获取“成员”
     * @returns { Boolean } true/false
     */
    isNodeFetchUser(data) {
      return data.userFetch || false;
    },

    /**
     * 设置 tree node checked 状态
     * @param { String|Number } id 成员id
     * @param { Boolean } value 勾选状态
     */
    setTreeNodeChecked(id, isChecked) {
      const key = 'member_' + id;
      if (this.treeMember[key]) {
        this.treeMember[key].forEach((item) => item.checked = isChecked);
      }
    },

    /**
     * 节点展开事件
     * @desc 异步加载部门下成员列表
     */
    handleTreeNodeExpand(data, node, nodeEle) {
      if (!this.isNodeFetchUser(data)) {
        this.getDeptMemberList(data.deptId);
      }
    },

    /**
     * 树视图节点勾选改变事件
     * @param { Object } data 节点数据对象
     * @param { Boolean } checked 节点是否被勾选
     */
    handleTreeNodeCheck(data, checked) {
      data.checked = checked;
      this.syncCheckedState(data, checked);
      this.syncCheckAllState(this.checkedValue);
      // 同步 tree 视图相同成员节点勾选状态
      this.setTreeNodeChecked(data.id, checked);
    },


    /** 已选成员相关 **/
    /**
     * 获取已选成员列表
     */
    getMemberListChecked() {
      this.viewLoading(true);
      memberAPI.memberListByRole({ roleId: this.activeRoleId }).then((res) => {
        this.viewLoading(false);
        if (res.code == 200 && res.data) {
          this.checkedList = res.data;

          // 初始化 tree 视图
          this.initTreeData();
        }
      }).catch((error) => {
        this.viewLoading(false);
        console.error('Get member list checked error: ', error.message);
      });
    },

    /**
     * 已选视图，全选 checkbox 事件
     */
    handleCheckAll() {
      if (this.indeterminate) {
        this.checkAll = false;
      } else {
        this.checkAll = !this.checkAll;
      }
      this.indeterminate = false;
      this.checkedList.forEach((item) => {
        item.checked = this.checkAll;
        // 同步 tree 组件 checked
        this.setTreeNodeChecked(item.id, this.checkAll);
      });
    },

    /**
     * 已选视图，checkbox 改变事件
     * @param { Object } data 数据项
     * @param { Boolean } value 勾选状态
     */
    handleCheckboxChangeCallback(data, value) {
      this.syncCheckAllState(this.checkedValue);
      // 同步 tree 组件 checked
      this.setTreeNodeChecked(data.id, value);
    },

    /**
     * 同步权限/半选状态
     * @param { Array } keys 已选数据项 id
     */
    syncCheckAllState(keys) {
      if (keys.length === this.checkedList.length) {
        this.checkAll = true;
        this.indeterminate = false;
      } else if (keys.length > 0) {
        this.checkAll = false;
        this.indeterminate = true;
      } else {
        this.checkAll = false;
        this.indeterminate = false;
      }
    },

    /**
     * 同步勾选状态
     * @param { Object } data 数据项
     * @param { Boolean } isChecked 是否勾选
     */
    syncCheckedState(data, isChecked) {
      const index = this.checkedList.findIndex((item) => item.id == data.id);
      if (isChecked && index === -1) {
        this.checkedList.push({
          id: data.id,
          name: data.name,
          checked: isChecked
        });
      } else {
        this.checkedList[index].checked = isChecked;
      }
    },


    /** footer 操作按钮相关 **/
    /**
     * 保存点击事件
     */
    handleSaveClick() {
      if (!this.checkedValue.length) {
        this.$notice.error({
          title: intl.$t("操作失败"),
          message: intl.$t("请选择成员")
        });
        return;
      }
      this.$emit('confirm', this.checkedValue);
    },

    /**
     * 关闭点击事件
     */
    handleCloseClick() {
      this.$emit('input', false);
    }
  }
};
</script>

<style lang="scss" src="../common/tree.scss" scoped>

</style>

<style lang="scss" scoped>
@import "@/style/const";

  .modal-box {
    height: 100%;
    overflow: hidden;
  }

  .modal-body {
    height: calc(100% - 52px - 10px);
    overflow: hidden;
    position: relative;
    margin-left: 16px;
    margin-right: 16px;

    .tree-section {
      height: calc(100% - 40px);
      overflow: auto;
    }
  }

  // 自定义搜素控件
  .filter-widget {
    height: 100%;
    border: 1px solid $theme-color;
    border-radius: 2px;

    .filter-item {
      height: 100%;
      color: $theme-color;
      background-color: #FFF;
      text-align: center;
      line-height: 40px;
      display: inline-block;
      vertical-align: middle;
      padding-left: 8px;
      padding-right: 8px;
      cursor: pointer;
    }

    .filter-item.active {
      color: #FFF;
      background-color: $theme-color;
    }
  }

  // 已选成员列表
  .check-list {
    .list-item {
      height: 30px;
      line-height: 30px;
      margin-left: 25px;
    }

    .level-1 {
      font-weight: bold;
    }
  }
</style>
