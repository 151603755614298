<template>
<div class="page-box">
    <div class="modal-body lz-modal__body">
      <!-- 左面板视图 -->
      <div class="left-panel pr-20">
        <div class="title-section">
          <span>{{$t('选择酒店')}}</span>
          <span>{{$t('（已选：')}}<span class="highlight-title">{{hotelCheckedItems.length}}</span>{{$t('家酒店）')}}</span>

          <!-- tips 视图 -->
          <div class="tips-view">
            <el-popover :width="290" trigger="hover" placement="bottom">
              <div slot="reference" class="icon">
                <lz-icon type="iconfont" name="lz-icon lzicon-question_circle_outline" :size="14" color="#474F64"></lz-icon>
              </div>
              <div style="font-size: 12px;">
                <div>{{$t('选择地域、品牌、省市，后续如此范围内如新增酒店，新增酒店也会按通用角色给此用户自动添加。')}}</div>
                <div class="mt-5">{{$t('只勾选酒店，则后续新增酒店不会给此用户自动添加。')}}</div>
              </div>
            </el-popover>
          </div>
        </div>

        <div class="content-section">
          <!-- 搜索视图 -->
          <div class="search-section">
            <search-list ref="searchList" :placeholder="$t('搜索酒店名')" :props="searchProps" :showCheckbox="true" :search-list="searchList" @search="handleSearchCallback" @cancel="handleSearchCancelCallback" @check-change="handleSearchCheckChangeCallback"></search-list>
          </div>

          <!-- tree + filter 视图 -->
          <div v-show="!isSearchingState()" class="tree-section pt-10">
            <div class="filter-view">
              <div v-for="item in filterTypeEnum" :key="item.value" :class="['filter-view__item', { actived: filterType===item.value }]" @click="handleFilterItemClick(item.value)">{{item.label}}</div>
            </div>
            <div class="action-view">
              <el-button class="action-item" type="text" @click="handleTreeCheckAllClick(true)">{{$t('选择全部')}}</el-button>
              <el-button class="action-item" type="text" @click="handleTreeCheckAllClick(false)">{{$t('取消选中')}}</el-button>
            </div>

            <!-- region hotel tree -->
            <div v-show="this.isFilterTypeRegion" class="tree-content pb-10">
              <el-tree ref="regionTree" class="tree-view" :data="regionTreeData" node-key="id" show-checkbox check-strictly :highlight-current="false" :empty-text="regionEmptyText" :default-checked-keys="regionCheckedKeys" :render-content="renderTreeContent" @check="handleTreeNodeCheck" @node-expand="handleTreeNodeExpand"></el-tree>
            </div>
            <!-- area hotel tree -->
            <div v-show="this.isFilterTypeArea" class="tree-content pb-10">
              <el-tree ref="areaTree" class="tree-view" :data="areaTreeData" node-key="id" show-checkbox check-strictly :highlight-current="false" :empty-text="areaEmptyText" :default-checked-keys="areaCheckedKeys" :render-content="renderTreeContent" @check="handleTreeNodeCheck" @node-expand="handleTreeNodeExpand"></el-tree>
            </div>
            <!-- brand hotel tree -->
            <div v-show="this.isFilterTypeBrand" class="tree-content pb-10">
              <el-tree ref="brandTree" class="tree-view" :data="brandTreeData" node-key="id" show-checkbox check-strictly :highlight-current="false" :empty-text="brandEmptyText" :default-checked-keys="brandCheckedKeys" :render-content="renderTreeContent" @check="handleTreeNodeCheck" @node-expand="handleTreeNodeExpand"></el-tree>
            </div>
          </div>
        </div>
      </div>

      <!-- 右面板视图 -->
      <div class="right-panel pl-20">
        <div class="title-section">
          <span>{{$t('分配酒店角色')}}</span>

          <!-- tips 视图 -->
          <div class="tips-view ml-10">
            <el-popover :width="290" trigger="hover" placement="bottom">
              <div slot="reference" class="icon">
                <lz-icon type="iconfont" name="lz-icon lzicon-question_circle_outline" :size="14" color="#474F64"></lz-icon>
              </div>
              <div style="font-size: 12px;">
                <div>{{$t('选择通用角色可批量设置所选酒店的角色权限。')}}</div>
                <div class="mt-5">{{$t('选择酒店角色，只能设置对应酒店下创建的角色权限。')}}</div>
              </div>
            </el-popover>
          </div>
        </div>

        <div class="content-section">
          <!-- 批量设置视图 -->
          <div class="select-section">
            <span class="prefix">{{$t('批量设置通用角色')}}</span>
            <el-popover v-model="showRoleSelect" :width="220" placement="bottom" :visible-arrow="false" popper-class="popper-select">
              <el-input slot="reference" style="width: 220px;" :value="(generalRoleItem&&generalRoleItem[rolePropLabel]) || ''" readonly :placeholder="$t('未选择角色')" suffix-icon="el-icon-arrow-down"></el-input>

              <transition name="fade-common">
                <search-select-list v-if="showRoleSelect" :list-items="generalRoleData" :defalut-sel-id="(generalRoleItem&&generalRoleItem[rolePropValue]) || ''" @selected="data => handleSearchSelectSelectedCallback(-1, data)"></search-select-list>
              </transition>
            </el-popover>
          </div>

          <!-- 门店角色列表 -->
          <div class="table-section pt-10">
            <div class="ts-head">
              <div class="ts-tr">
                <div class="ts-th">{{$t('已选中门店')}}</div>
                <div class="ts-th">{{$t('角色')}}</div>
              </div>
            </div>
            <div class="ts-body">
              <div v-for="(item, index) in hotelCheckedItems" :key="item.code" class="ts-tr role-list-item display__flex">
                <div class="ts-td">
                  <div class="text-item">
                    <span>{{item.name}}</span>
                  </div>
                </div>
                <div class="ts-td">
                  <el-popover v-model="item.showRoleSelect" :width="220" placement="bottom" popper-class="popper-select" @show="handleRolePopoverShow(item.code)">
                    <el-input slot="reference" style="width: 220px; line-height: 32px;" :value="hotelRoleItemSelected(item)[rolePropLabel]" readonly :placeholder="$t('未选择角色')" suffix-icon="el-icon-arrow-down"></el-input>

                    <transition name="fade">
                      <search-select-list v-if="item.showRoleSelect" :loading="isLoadingRoleList(item.code)" :list-items="enabledRoleList(item.code)" :defalut-sel-id="hotelRoleItemSelected(item)[rolePropValue]" @selected="data => handleSearchSelectSelectedCallback(index, data)"></search-select-list>
                    </transition>
                  </el-popover>

                  <div class="remove-item" @click="handleRemoveHotelRoleItem(index)">
                    <img src="~@/assets/images/tag-close.png"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="lz-modal__footer lz-modal__footer__line">
      <el-button type="primary" size="small" class="lz-modal__footer__button confirm" @click="handleSaveClick">{{$t('确定')}}</el-button>
      <el-button size="small" class="lz-modal__footer__button cancel" @click="handleCloseClick">{{$t('取消')}}</el-button>
    </div>
  </div>
</template>

<script>
import { intl } from "@tci18n/vue2";import { mapState } from 'vuex';
import hotelTreeMixin from './mixins/hotelTree';
import hotelRoleMixin from './mixins/hotelRole';
import SearchList from '../../common/search.vue';
import SearchSelectList from '@/view/components/SearchList/SearchList';
import commonAPI from '../../../common/api';

/**
 * 酒店和角色设置视图
 * @module @/view/organization
 */
export default {
  name: 'SelectHotelRole',
  mixins: [hotelTreeMixin, hotelRoleMixin],
  components: {
    SearchList,
    SearchSelectList
  },
  props: {
    // 已绑定的角色
    generalRoleList: {
      type: Array,
      default: function () {
        return [];
      }
    },

    // 已绑定的酒店角色列表（只包含有权限的酒店）
    hotelRoleList: {
      type: Array,
      default: function () {
        return [];
      }
    },

    // 已绑定的城市列表
    regionList: {
      type: Array,
      default: function () {
        return [];
      }
    },

    // 已绑定的区域列表
    areaList: {
      type: Array,
      default: function () {
        return [];
      }
    },

    // 已绑定的品牌列表
    brandList: {
      type: Array,
      default: function () {
        return [];
      }
    },

    // 是否可提示
    notifiable: {
      type: Boolean
    }
  },
  data() {
    return {
      // search-list 组件 props
      searchProps: {
        value: 'hotelVid',
        label: 'hotelName'
      },
      searchKeyword: '',
      // 搜索结果
      searchList: undefined
    };
  },
  computed: {
    ...mapState('org', [
    'generalRoleData']
    ),

    // 勾选的数据 id 集合
    checkedHotelValue() {
      return this.hotelCheckedItems.map((item) => item.code);
    },

    // 获取组件引用
    searchListRef() {
      return this.$refs['searchList'];
    }
  },
  created() {
    this.initData();
    this.initTreeData();
  },
  methods: {
    /**
     * 设置视图 loading 状态
     */
    viewLoading(loading) {
      this.$emit('loading', loading);
    },

    initData() {
      this.initHotelRoleData();
      this.initTreeCheckedData();
    },


    /** 搜索相关 **/
    /**
     * 搜索酒店
     * @param { String } keyword 搜素关键字
     */
    searchHotel(keyword) {
      const params = { hotelName: keyword };
      this.viewLoading(true);
      commonAPI.hotelListByName(params).then((res) => {
        this.viewLoading(false);
        if (res.code == 200 && res.data) {
          this.searchKeyword = keyword;
          this.searchList = res.data;

          this.searchListRef.setChecked(this.checkedHotelValue);
        } else {
          this.$notice.error(res.message);
        }
      }).catch((error) => {
        this.viewLoading(false);
        this.$notice.error(intl.$t("系统异常，请稍后再试"));
        console.error('Search hotel error: ', error.message);
      });
    },

    /**
     * 是否处于搜索状态
     */
    isSearchingState() {
      return this.$refs['searchList'] != undefined && this.$refs['searchList'].isSearching;
    },

    /**
     * 搜索事件回调
     */
    handleSearchCallback(value) {
      if (this.searchKeyword != value) {
        this.searchList = undefined;
        this.searchHotel(value);
      }
    },

    /**
     * 搜索取消事件回调
     */
    handleSearchCancelCallback() {
      this.searchList = undefined;
      this.searchKeyword = '';
    },

    /**
     * 搜索项 check 回调事件
     * @param { Object } data 数据项
     * @param { Boolean } isChecked 是否勾选
     */
    handleSearchCheckChangeCallback(data, isChecked) {
      const hotelItem = { code: data.hotelVid, name: data.hotelName };
      this.syncHotelItemCheckState(hotelItem, isChecked);
      this.syncTreeHotelNodeCheckState(hotelItem.code, isChecked);
    },


    /** footer 操作按钮相关 **/
    /**
     * 保存点击事件
     */
    handleSaveClick() {
      if (!this.hotelCheckedItems.length) {
        this.$notice.error({
          title: intl.$t("操作失败"),
          message: intl.$t("请选择酒店角色")
        });
        return;
      } else if (this.hotelCheckedItems.length) {
        const result = this.validateHotelChecked(this.hotelCheckedItems);
        if (result !== true) {
          this.$notice.error({
            title: intl.$t("操作失败"),
            message: result.message
          });
          return;
        }
      }
      this.hotelCheckedItems.forEach((item) => item.hotelVid = item.code);
      this.$emit('complete', this.generalRoleItem, this.hotelCheckedItems, this.regionCheckedItems, this.areaCheckedItems, this.brandCheckedItems);
    },

    /**
     * 校验酒店角色配置
     * @returns { Boolean|Error } true/error
     */
    validateHotelChecked(checkedItems) {
      // disabled 判断当前操作人是否具有酒店当前绑定的角色权限
      // 若角色类型是酒店角色且无权限，则在“分配酒店角色”视图重新分配，需要指定其他有权限的角色
      const item = checkedItems.find((item) => {
        const roleData = this.getHotelRoleData(item);
        return !roleData || roleData[this.rolePropDisabled] && this.isRoleTypeHotel(roleData[this.rolePropType]);
      });
      if (item) {
        return new Error(intl.$t(`请选择{slot0}对应的角色`, { "slot0": item.name }));
      } else {
        return true;
      }
    },

    /**
     * 关闭点击事件
     */
    handleCloseClick() {
      this.$emit('input', false);
    }
  }
};
</script>

<style lang="scss" src="../../common/tree.scss" scoped>

</style>

<style lang="scss" scoped>
@import "@/style/const";
  @import "@/style/mixins";

  .modal-body {
    height: calc(100% - 52px);
    overflow: hidden;
    padding: 16px 16px 0;

    .title-section {
      font-size: 18px;
      font-weight: bold;
      line-height: 1;

      .highlight-title {
        color: $theme-color;
      }
    }

    .tips-view {
      display: inline-block;
      vertical-align: top;
      font-size: 14px;
      font-weight: normal;
      line-height: 18px;

      .icon {
        cursor: help;
      }

      .lz-icon {
        line-height: 1;
        vertical-align: top;
        margin-top: 2px;
      }
    }    
  }

  .left-panel .content-section,
  .right-panel .content-section {
    height: calc(100% - 18px);
    overflow: hidden;
    position: relative;
  }

  .left-panel {
    width: 37%;
    height: 100%;
    border-right: 1px solid $border-color;
    display: inline-block;
    vertical-align: top;

    .search-section {
      margin-top: 12px;
    }

    .tree-section {
      height: calc(100% - 40px - 10px);
      overflow: hidden;
    }

    .filter-view {
      height: 32px;

      .filter-view__item {
        width: 30%;
        height: 100%;
        line-height: 30px;
        border-radius: 2px;
        border: 1px solid $theme-color;
        background-color: #FFF;
        color: $theme-color;
        text-align: center;
        float: left;
        cursor: pointer;
        user-select: none;
      }

      .filter-view__item + .filter-view__item {
        margin-left: 15px;
      }

      .filter-view__item.actived {
        background-color: $theme-color;
        color: #FFF;
      }
    }

    .action-view {
      padding-top: 8px;
      padding-bottom: 6px;

      .action-item {
        height: 22px;
        color: $theme-color;
        border: none;
        padding: 0;
      }

      .action-item + .action-item {
        margin-left: 15px;
      }
    }

    
    // tree content
    .tree-content {
      height: calc(100% - 32px - 36px);
      overflow-y: auto;
    }
  }

  .right-panel {
    width: 63%;
    height: 100%;
    display: inline-block;
    vertical-align: top;

    .select-section {
      margin-top: 12px;

      .prefix {
        margin-right: 12px;
      }
    }

    .table-section {
      height: calc(100% - 32px - 12px);
      overflow: hidden;

      .ts-body {
        height: calc(100% - 56px);
        overflow-y: auto;
      }
      
      .ts-head {
        border: 1px solid $border-color;
      }

      $_height: 54px;
      .ts-tr {
        height: $_height;
      }

      .ts-tr .ts-th {
        height: 100%;
        font-weight: 700;
        line-height: $_height;
      }

      .ts-head .ts-tr {
        background-color: #EBF0FA;
      }

      .ts-body .ts-tr {
        border: 1px solid $border-color;
        border-top: none;
      }

      .ts-th,
      .ts-td {
        display: inline-block;
        vertical-align: top;
        padding-left: 20px;
        padding-right: 17px;
      }

      .ts-tr .ts-th:first-child,
      .ts-tr .ts-td:first-child {
        width: 45%;
      }

      .ts-tr .ts-th:last-child,
      .ts-tr .ts-td:last-child {
        width: 55%;
        border-left: 1px solid $border-color;
      }

      .ts-td {
        line-height: $_height;
      }

      .text-item {
        display: inline-block;
        vertical-align: middle;
      }

      .text-item span {
        @include no-wrap-multi(2);
        line-height: 1.2;
      }

      .remove-item {
        width: calc(100% - 220px);
        height: 32px;
        line-height: 32px;
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        text-align: center;
      }

      .remove-item img {
        width: 12px;
        height: 12px;
      }
    }
  }
</style>

<style>
.popper-select {
    padding-left: 0;
    padding-right: 0;
    margin-top: 5px !important;
  }
</style>
