import { intl } from "@tci18n/vue2";import commonAPI from '../../api/common';
import memberAPI from '../../api/member';

import { mapGetters } from 'vuex';

/**
 * Member edit fetch mixin
 */
export default {
  computed: {
    ...mapGetters([
    'userOrgCode',
    'userOrgName']
    )
  },
  methods: {
    /**
     * 获取成员信息
     */
    getMemberInfo() {
      this.viewLoading(true);
      memberAPI.memberInfo({ id: this.id }).then((res) => {
        this.viewLoading(false);
        if (res.code == 200 && res.data) {
          this.processMemberInfo(res.data);
        } else {
          this.$notice.error(res.message);
        }
      }).catch((error) => {
        this.viewLoading(false);
        this.$notice.error(intl.$t("系统异常，请稍后再试"));
        console.error('Get member info error: ', error.message);
      });
    },

    /**
     * 成员信息数据处理
     */
    processMemberInfo(data) {
      this.formData.userName = data.userName;
      this.formData.accountUserName = data.accountUserName;
      this.formData.mobile = data.mobile;
      this.formData.email = data.email;
      this.formData.nickName = data.nickName;
      this.formData.openId = data.openId;
      this.formData.jobId = data.jobId || '';
      this.formData.oaNo = data.oaNo;
      this.formData.state = data.state;
      this.formData.depts = data.depts;
      this.formData.showDepts = data.showDeptIds;
      // “所属酒店”是否选择“集团”判断
      if (data.userHotelId == this.userOrgCode) {
        this.formData.userHotelId = this.userOrgCode;
        this.formData.userHotelName = this.userOrgName;
      } else {
        this.formData.userHotelId = data.userHotelId;
        this.formData.userHotelName = data.userHotelName;
      }
      // 已分配的角色
      this.generalRoleList = this.isHotelRoleSelectable ? data.blocRoles : data.blocRoles.filter((item) => !item.disabled);
      this.hotelRoleList = data.hotelList;
      this.regionList = data.userRegionList;
      this.areaList = data.userAreaList;
      this.brandList = data.userBrandList;
      this.formData.blocSet = data.managerBlocCodeList.map((item) => item.blocCode);
      this.formData.brands = data.brands;
      this.brands = data.brands;

      this.$emit('detail', data);
    },

    /**
     * 保存成员信息
     */
    saveMemberInfo() {
      const params = this.processFormData(this.formData);
      this.viewLoading(true);
      memberAPI.memberSave(params).then((res) => {
        this.viewLoading(false);
        if (res.code == 200) {
          this.$notice.success(intl.$t(`{slot0}成员成功！`, { "slot0": this.isActionAdd ? '新增' : '编辑' }));

          this.$emit('action', this.isActionAdd ? 'add' : 'edit');
          this.$store.commit('org/setActiveMember', { id: this.id, name: params.userName });
          this.handleCloseClick();
        } else {
          this.$notice.error(res.message);
        }
      }).catch((error) => {
        this.viewLoading(false);
        this.$notice.error(intl.$t("系统异常，请稍后再试"));
        console.error('Save member info error: ', error.message);
      });
    },

    /**
     * 处理生成 FormData
     * @params { Object } data form原始数据
     * @return { Object } 用于提交的数据
     */
    processFormData(data) {
      let form = {
        userName: data.userName.trim(),
        userAccountName: data.accountUserName.trim(),
        mobile: data.mobile,
        email: data.email,
        jobId: data.jobId,
        oaNo: data.oaNo.trim(),
        state: data.state,
        userHotelId: data.userHotelId,
        deptIds: data.depts.map((item) => item.id),
        showDeptIds: data.showDepts.map((item) => item.id),
        // 勾选的城市、区域、品牌（新版逻辑）
        userRegionList: this.regionList,
        userAreaList: this.areaList,
        userBrandList: this.brandList,
        brands: this.brands
      };
      if (this.isActionAdd) {
        form.userPwd = data.userPwd;
      } else {
        form.userId = this.id;
      }
      // 已分配的角色
      form.blocRoleIds = this.generalRoleList.map((item) => item.id);
      let userHotelRoleList = [];
      this.hotelRoleList.forEach((item) => {
        let hotelRole = {
          hotelVid: item.hotelVid,
          roleIds: item.roleList.map((roleItem) => roleItem.id)
        };
        userHotelRoleList.push(hotelRole);
      });
      form.userHotelRoleList = userHotelRoleList;
      if (data.blocSet && data.blocSet.length) {
        form.managerBlocCodeList = this.isBlocSetAllSelected ? this.blocSetDatas.map((item) => item.blocCode) : data.blocSet;
      }
      return form;
    },


    /**
     * 自动生成账号
     */
    createAccount() {
      const params = { userName: this.formData.userName.trim() };
      memberAPI.memberAccountCreate(params).then((res) => {
        if (res.code == 200 && res.data) {
          this.formData.accountUserName = res.data;

          // TODO：2.0与1.x过渡逻辑，全量切换后需删除
          this.checkAccount(res.data);
        }
      }).catch((error) => {
        console.error('Create account error: ', error.message);
      });
    },

    /**
     * 自动生成密码
     * @note 受“设置-自动生成密码“配置控制
     */
    createPassword() {
      commonAPI.pwdAutoCreate().then((res) => {
        if (res.code == 200 && res.data) {
          this.formData.userPwd = res.data;
        }
      }).catch((error) => {
        console.error('Create password error: ', error.message);
      });
    },

    /**
     * 生成随机密码
     * @note 不受“设置-自动生成密码”配置控制
     */
    generatePassword() {
      this.dialogLoading = true;
      commonAPI.pwdGenerate().then((res) => {
        this.dialogLoading = false;
        if (res.code == 200 && res.data) {
          this.processAfterGeneratePWD(res.data);
        }
      }).catch((error) => {
        this.dialogLoading = false;
        console.error('Generate password error: ', error.message);
      });
    },

    /**
     * 重置并发送密码
     * @param { String } data 新密码
     */
    resetAndSendPassword(data) {
      const params = {
        userId: this.id,
        userPwd: data,
        mobile: this.formData.mobile,
        email: this.formData.email
      };
      this.dialogLoading = true;
      commonAPI.pwdReset(params).then((res) => {
        this.dialogLoading = false;
        if (res.code == 200) {
          this.$notice.success(intl.$t("重置密码成功！"));

          this.showResetPwdDialog = false;
        } else {
          this.$notice.error(res.message);
        }
      }).catch((error) => {
        this.dialogLoading = false;
        this.$notice.error(intl.$t("系统异常，请稍后再试"));
        console.error('Reset and send password error: ', error.message);
      });
    },

    /**
     * 解绑微信号
     */
    unbindWX() {
      this.formItemLoading = true;
      memberAPI.memberUnbindWX({ id: this.id }).then((res) => {
        this.formItemLoading = false;
        if (res.code == 200) {
          this.formData.nickName = '';
          this.formData.openId = '';
        } else {
          this.$notice.error(res.message);
        }
      }).catch((error) => {
        this.formItemLoading = false;
        this.$notice.error(intl.$t("系统异常，请稍后再试"));
        console.error('Unbind WX error: ', error.message);
      });
    },

    /**
     * 修改成员状态
     */
    updateState() {
      const params = {
        id: this.id,
        state: this.formData.state === 1 ? 0 : 1
      };
      this.viewLoading(true);
      memberAPI.memberStateUpdate(params).then((res) => {
        this.viewLoading(false);
        if (res.code == 200) {
          this.$notice.success(intl.$t("修改成员状态成功！"));

          this.formData.state = params.state;
          this.$emit('action', 'update-state');
          this.handleCloseClick();
        } else {
          this.$notice.error(res.message);
        }
      }).catch((error) => {
        this.viewLoading(false);
        this.$notice.error(intl.$t("系统异常，请稍后再试"));
        console.error('Update member state error: ', error.message);
      });
    },

    /**
     * 删除成员
     */
    deleteMember() {
      const params = { ids: [this.id] };
      this.viewLoading(true);
      memberAPI.memberDelete(params).then((res) => {
        this.viewLoading(false);
        if (res.code == 200) {
          this.$notice.success(intl.$t("删除成员成功！"));

          this.$emit('action', 'delete');
          this.handleCloseClick();
        } else {
          this.$notice.error(res.message);
        }
      }).catch((error) => {
        this.viewLoading(false);
        this.$notice.error(intl.$t("系统异常，请稍后再试"));
        console.error('Delete member error: ', error.message);
      });
    },


    /** 酒管相关 **/
    /**
     * 获取“酒管”下拉列表
     */
    getBlocSetSelectList(callback) {
      commonAPI.tenantBlocSetSelectList().then((res) => {
        if (res.code == 200 && res.data) {
          callback(res.data);
        }
      }).catch((error) => {
        console.error('Get tenant bloc set select list error: ', error.message);
      });
    },

    /**
     * 查询是否能编辑“艺科工号”
     */
    checkCanEditOANo() {
      commonAPI.checkInternalBloc().then((res) => {
        if (res.code == 200) {
          this.isOANoEnabled = res.data;
        }
      }).catch((error) => {
        console.error('check can edit OANo error: ', error.message);
      });
    },

    /**
     * 获取“酒管”数据权限级联下拉列表
     */
    getBlocSetCascadeList(callback) {
      commonAPI.getBlocCascadeList({ userId: this.id || '' }).then((res) => {
        if (res.code == 200 && res.data) {
          callback([res.data]);
        }
      }).catch((error) => {
        console.error('Get bloc cascade list error: ', error.message);
      });
    }
  }
};