import { intl } from "@tci18n/vue2";import systemHelper from '@/libs/system';


/**
 * Member edit oem mixin
 */
export default {
  data() {
    return {
      // 是否可填写“艺科工号”
      isOANoEnabled: false
    };
  },
  computed: {
    // 模板信息
    templateMode() {
      return systemHelper.getTemplateMode();
    },

    // “账号” input placeholder
    accountPlaceholder() {
      return intl.$t("请输入账号");
    }
  }
};