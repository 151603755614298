<template>
<div class="page-box">
    <tree-head refer="role" :rootData="rootData"></tree-head>

    <!-- 树视图组件 -->
    <el-tree class="tree-view row-h40" ref="tree" :node-key="nodeKey" :highlight-current="false" :empty-text="$t('暂无数据')" :data="data" :default-expanded-keys="defaultExpandedKeys" :render-content="renderTreeContent" @current-change="handleTreeCurrentChange" @node-expand="handleTreeNodeExpand"></el-tree>

    <!-- 新增/编辑 -->
    <lz-drawer v-model="showDrawer" name="role-edit" :width="1066" :loadingEnable="true" :loading="drawerLoading" :title="drawerConfig.title" :before-close="handleDrawerBeforeClose" @close="drawerLoading = false">
      <edit-view v-if="showDrawer" v-model="showDrawer" ref="editView" :action="drawerConfig.action" :id="drawerConfig.data.id" :hotel-data="drawerConfig.data.hotelData" @loading="(loading) => { drawerLoading = loading }" @action="handleEditDrawerCallback"></edit-view>
    </lz-drawer>

    <!-- 删除 -->
    <lz-dialog v-model="showDialog" name="delete-role" width="360px" :title="dialogConfig.title" :confirm-button-text="$t('确认')" :mask-closable="!dialogLoading" loading-enable :loading="dialogLoading" @confirm="handleDeleteDialogConfirm()"></lz-dialog>
  </div>
</template>

<script>
import { intl } from "@tci18n/vue2";import { mapGetters, mapMutations } from 'vuex';
import TreeHead from './treeHead.vue';
import EditView from '../role/edit.vue';
import roleAPI from '../api/role';

/**
 * 角色树视图
 * @module @/view/organization
 */
export default {
  name: "RoleTree",
  components: {
    TreeHead,
    EditView
  },
  props: {
    // 根节点数据
    rootData: {
      type: Object
    },

    // 酒店列表
    hotelList: Array
  },
  data() {
    return {
      nodeKey: 'key',
      // tree 原始数据
      data: null,
      // 默认展开节点 keys
      defaultExpandedKeys: [],

      drawerLoading: false,
      showDrawer: false,
      drawerConfig: {
        title: '',
        // 操作类型（1-新增，2-编辑, 3-复制）
        action: 1,
        data: {}
      },

      dialogLoading: false,
      showDialog: false,
      dialogConfig: {
        title: intl.$t("是否确认删除？"),
        data: null
      }
    };
  },
  computed: {
    ...mapGetters([
    'pageButtonPermission',
    'isTenantTypeBlocUser']
    ),

    ...mapGetters('org', [
    'activeRoleId']
    ),

    // 是否“门店角色”可用
    isRoleTypeHotelEnable() {
      return this.isTenantTypeBlocUser;
    },

    // 是否具有角色“新增”权限
    isRoleAddPermission() {
      return this.pageButtonPermission('hrms/organization', 'addRoleButton');
    },

    // 是否具有角色“复制”权限
    isRoleCopyPermission() {
      return this.pageButtonPermission('hrms/organization', 'copyRoleButton');
    },

    // 是否具有角色“编辑”权限
    isRoleEditPermission() {
      return this.pageButtonPermission('hrms/organization', 'editRoleInfoButton');
    },

    // 是否具有角色“删除”权限
    isRoleDeletePermission() {
      return this.pageButtonPermission('hrms/organization', 'deleteRoleButton');
    }
  },
  created() {
    this.initData();
  },
  methods: {
    ...mapMutations('org', [
    'setActiveRole']
    ),

    initData() {
      this.data = [
      { key: 'group', title: intl.$t("通用角色"), children: [] }];

      // “集团租户”展示“门店角色”
      if (this.isRoleTypeHotelEnable) {
        this.data.push({ key: 'hotel', title: intl.$t("门店角色"), children: [] });
      }
    },


    /**
     * 树视图渲染
     */
    renderTreeContent(h, { node, data, store }) {
      if (node.level === 1 || this.isNodeHotel(data)) {
        // 父级节点可展开逻辑；酒店节点可展开异步加载数据
        node.isLeaf = false;
      }

      let rowElements = [];
      let prefixElments = [];
      if (node.level === 1) {
        prefixElments.push(
          h('i', {
            class: 'tree-folder__icon iconfont lzicon-person_couple'
          })
        );
      }
      prefixElments.push(
        // 节点标题
        h('span', {
          class: `tree-node__title ${this.isNodeSelected(data) ? 'selected' : ''}`,
          attrs: {
            title: this.nodeTitle(data)
          }
        }, this.nodeTitle(data))
      );
      rowElements.push(
        h('div', {
          class: 'tree-row__prefix display__flex align_items__center'
        }, prefixElments)
      );

      let actionElements = [];
      if (this.isNodeGeneral(data) || this.isNodeHotel(data)) {
        actionElements.push(
          h('div', {
            style: this.isRoleAddPermission ? '' : 'display: none;',
            class: 'tree-row__action__item',
            on: {
              click: (event) => {
                // 节点新增事件
                this.handleAddTreeNode(data);
                // 阻止事件冒泡
                event.stopPropagation();
              }
            }
          }, [
          h('i', {
            class: 'iconfont lzicon-add_circle_outline'
          })]
          )
        );
      }
      if (this.isNodeRole(data)) {
        actionElements.push(
          h('div', {
            style: this.isRoleCopyPermission ? '' : 'display: none;',
            class: 'tree-row__action__item',
            on: {
              click: (event) => {
                // 节点复制事件
                this.handleCopyTreeNode(data, node);
                // 阻止事件冒泡
                event.stopPropagation();
              }
            }
          }, [
          h('i', {
            class: 'iconfont lzicon-copy_outline'
          })]
          ),
          h('div', {
            style: this.isRoleEditPermission ? '' : 'display: none;',
            class: 'tree-row__action__item',
            on: {
              click: (event) => {
                // 节点编辑事件
                this.handleEditTreeNode(data, node);
                // 阻止事件冒泡
                event.stopPropagation();
              }
            }
          }, [
          h('i', {
            class: 'iconfont lzicon-edit_outline'
          })]
          ),
          h('div', {
            style: this.isRoleDeletePermission ? '' : 'display: none;',
            class: 'tree-row__action__item',
            on: {
              click: (event) => {
                // 节点删除事件
                this.handleDeleteTreeNode(data);
                // 阻止事件冒泡
                event.stopPropagation();
              }
            }
          }, [
          h('i', {
            class: 'iconfont lzicon-delete'
          })]
          )
        );
      }
      rowElements.push(
        h('div', {
          class: `tree-row__suffix ${node.hover ? '' : 'display-none'}`
        }, actionElements)
      );

      return h('div', {
        class: 'tree-row display__flex align_items__center justify_content__space_between',
        on: {
          mouseover: () => {
            this.$set(node, 'hover', true);
          },
          mouseout: () => {
            this.$set(node, 'hover', false);
          }
        }
      }, rowElements);
    },

    /**
     * 节点是否是“通用角色”父节点
     */
    isNodeGeneral(data) {
      return data[this.nodeKey] === this.data[0][this.nodeKey];
    },

    /**
     * 节点是否是“酒店”
     */
    isNodeHotel(data) {
      return data.isHotel;
    },

    /**
     * 节点是否是“角色”
     */
    isNodeRole(data) {
      return data.isRole;
    },

    /**
     * 节点是否是“酒店角色”
     */
    isNodeHotelRole(data) {
      return data.isHotelRole;
    },

    /**
     * 获取 tree node title
     * @param { Object } data 节点数据对象
     * @returns { String } node title
     */
    nodeTitle(data) {
      if (this.isNodeHotel(data)) {
        return data.hotelName;
      } else if (this.isNodeRole(data)) {
        return data.name;
      } else {
        return data.title;
      }
    },

    /**
     * 节点是否是 selected
     */
    isNodeSelected(data) {
      return this.isNodeRole(data) && data.id === this.activeRoleId;
    },

    /**
     * 新增节点事件
     * @param { Object } data 节点对象数据
     */
    handleAddTreeNode(data) {
      this.drawerConfig.title = intl.$t("新增角色");
      this.drawerConfig.action = 1;
      if (this.isNodeHotel(data)) {
        this.drawerConfig.data = {
          hotelData: { hotelVid: data.hotelVid, hotelName: data.hotelName }
        };
      } else {
        this.drawerConfig.data = {};
      }
      this.showDrawer = true;
    },

    /**
     * 编辑节点事件
     * @param { Object } data 节点对象数据
     * @param { Object } node 节点对象
     */
    handleEditTreeNode(data, node) {
      this.drawerConfig.title = intl.$t("编辑角色");
      this.drawerConfig.action = 2;
      this.drawerConfig.data = {
        id: data.id,
        nodeData: data
      };
      if (this.isNodeHotelRole(data)) {
        const parentData = node.parent.data;
        this.drawerConfig.data.hotelData = { hotelVid: parentData.hotelVid, hotelName: parentData.hotelName };
      }
      this.showDrawer = true;
    },

    /**
     * 复制节点事件
     * @param { Object } data 节点对象数据
     * @param { Object } node 节点对象
     */
    handleCopyTreeNode(data, node) {
      this.drawerConfig.title = intl.$t("复制角色");
      this.drawerConfig.action = 3;
      this.drawerConfig.data = {
        id: data.id,
        nodeData: data
      };
      if (this.isNodeHotelRole(data)) {
        const parentData = node.parent.data;
        this.drawerConfig.data.hotelData = { hotelVid: parentData.hotelVid, hotelName: parentData.hotelName };
      }
      this.showDrawer = true;
    },

    /**
     * 新增/编辑/复制成功回调
     * @param { Object } data 角色数据
     * @param { Boolean } isRoleTypeHotel 是否是酒店角色
     */
    handleEditDrawerCallback(data, isRoleTypeHotel) {
      if (this.drawerConfig.action === 1 || this.drawerConfig.action === 3) {
        if (isRoleTypeHotel) {
          const treeRef = this.$refs['tree'];
          const node = treeRef.getNode(data.hotelVid);
          this.getHotelRoleList(data.hotelVid).then((roleList) => {
            treeRef.updateKeyChildren(node.key, roleList);
            node.expanded = true;
          });
        } else {
          this.$emit('add');
        }
      } else if (this.drawerConfig.action === 2) {
        let nodeData = this.drawerConfig.data.nodeData;
        nodeData.name = data.roleName;
        // 编辑的是当前选中的角色
        if (nodeData.id === this.activeRoleId) {
          this.setActiveRole(nodeData);
        }
      }
    },


    /**
     * Drawer 关闭前处理
     * @note 由于 Drawer 组件中未捕获 reject 异常，reject 时控制台会打印异常信息：Uncaught (in promise) undefined
     * @param { Function } done 关闭 drawer
     */
    handleDrawerBeforeClose(done) {
      if (this.$refs['editView'].checkBeforeClose()) {
        done();
      }
    },


    /** 角色删除 **/
    /**
     * 删除角色
     */
    deleteRole(roleId) {
      this.dialogLoading = true;
      roleAPI.roleDelete({ id: roleId }).then((res) => {
        this.dialogLoading = false;
        if (res.code == 200) {
          this.$notice.success(intl.$t("删除角色成功"));

          this.processAfterDeleteRole(roleId);
        } else {
          this.$notice.error(res.message);
        }
      }).catch((error) => {
        this.dialogLoading = false;
        this.$notice.error(intl.$t("系统异常，请稍后再试"));
        console.error('Delete role error: ', error.message);
      });
    },

    /**
     * 删除角色成功处理逻辑
     * @note
     * - 刷新树视图数据
     * - 如果删除节点也同时为当前选中的节点，更新选中节点为默认值（即未选中节点）
     * @param { String|Number } roleId 角色 Id
     */
    processAfterDeleteRole(roleId) {
      this.showDialog = false;
      this.$refs['tree'].remove(roleId);
      if (roleId == this.activeRoleId) {
        this.setActiveRole(null);
      }
      this.$emit('delete');
    },

    /**
     * 删除节点事件
     * @param { Object } data 节点对象数据
     * @param { Object } node 节点对象
     */
    handleDeleteTreeNode(data, node) {
      this.dialogConfig.data = data.id;
      this.showDialog = true;
    },

    /**
     * 删除 dialog 回调事件
     */
    handleDeleteDialogConfirm() {
      this.deleteRole(this.dialogConfig.data);
    },

    /**
     * 树节点选择事件
     * @param { Object } data 当前节点数据
     * @param { Object } node 当前节点对象
     */
    handleTreeCurrentChange(data, node) {
      if (this.isNodeRole(data) && !this.isNodeSelected(data)) {
        this.setActiveRole(data);
        this.$emit('current-change', data);
      }
    },

    /**
     * 树节点展开事件
     * @note 酒店节点展开异步获取角色数据
     */
    handleTreeNodeExpand(data, node, nodeELE) {
      // const index = this.defaultExpandedKeys.findIndex(id => id === data[this.nodeKey]);
      // if (index === -1) {
      //   this.defaultExpandedKeys.push(data[this.nodeKey]);
      // }

      // 酒店节点如果未获取子节点，则异步请求并展开（获取一次）
      if (this.isNodeHotel(data) && !data.children) {
        this.getHotelRoleList(data.hotelVid).then((roleList) => {
          this.$refs['tree'].updateKeyChildren(node.key, roleList);
        });
      }
    },

    /**
     * 获取“酒店角色”列表
     */
    getHotelRoleList(hotelVid) {
      return new Promise((resolve, reject) => {
        const params = { hotelVid };
        roleAPI.roleHotel(params).then((res) => {
          if (res.code == 200 && res.data) {
            if (res.data.length) {
              res.data.forEach((item) => {
                item[this.nodeKey] = item.id;
                item.isRole = true;
                item.isHotelRole = true;
              });
              resolve(res.data);
            }
          } else {
            reject(new Error(res.message));
          }
        }).catch((error) => {
          reject(error);
          console.error('Get hotel role list error: ', error.message);
        });
      });
    },



    /** 外部调用 **/
    /**
     * 设置组件数据
     * @note 通用角色数据
     * @param { Array } roleList 树视图数据
     * @param { Array } hotelList 酒店列表
     */
    setData(roleList, hotelList) {
      roleList.forEach((item) => {
        item[this.nodeKey] = item.id;
        item.isRole = true;
      });
      this.data[0].children = roleList;
      let defaultCheckedKeys = [this.data[0][this.nodeKey]];
      if (this.isRoleTypeHotelEnable) {
        this.data[1].children = hotelList.map((item) => {
          return {
            [this.nodeKey]: item.hotelVid,
            hotelVid: item.hotelVid,
            hotelName: item.hotelName,
            isHotel: true
          };
        });
        defaultCheckedKeys.push(this.data[1][this.nodeKey]);
      }
      this.defaultExpandedKeys = defaultCheckedKeys;

      // 默认选中第一个通用角色
      if (roleList.length) {
        this.setActiveRole(roleList[0]);
      }
    },

    /**
     * 更新组件数据
     * @param { Array } roleList 树视图数据
     */
    updateData(roleList) {
      roleList.forEach((item) => {
        item[this.nodeKey] = item.id;
        item.isRole = true;
      });
      this.data[0].children = roleList;
    },

    /**
     * 设置角色节点选中
     * @param { Object } data 角色数据
     */
    selectData(data) {
      if (data.id !== this.activeRoleId) {
        this.setActiveRole(data);
      }
    }
  }
};
</script>

<style lang="scss" src="../common/tree.scss" scoped>

</style>
