<template>
<div class="modal-box page-box">
    <div class="modal-body mt-10">
      <search-list class="search-section" ref="searchList" :placeholder="$t('搜索部门')" :props="searchProps" :show-radio="showRadio" :show-checkbox="showCheckbox" :show-shortcuts="showCheckbox" :search-list="searchList" @search="handleSearchCallback" @cancel="handleSearchCancelCallback" @select-change="handleSearchSelectChangeCallback" @check-change="handleSearchCheckChangeCallback"></search-list>

      <div v-show="!isSearchingState()" class="tree-section pb-15" :class="{ 'pt-15': !shortcutsEnable }">
        <div v-if="shortcutsEnable" class="action-view">
          <el-button class="action-item" type="text" @click="handleTreeCheckAllClick(true)">{{$t('选择全部')}}</el-button>
          <el-button class="action-item" type="text" @click="handleTreeCheckAllClick(false)">{{$t('取消选中')}}</el-button>
        </div>
        <tree-view ref="treeView" node-key="deptId" :editable="false" :deletable="false" :show-radio="showRadio" :show-checkbox="showCheckbox" :check-strictly="checkStrictly" :props="treeProps" @radio-change="handleTreeRadioChangeCallback" @check-change="handleTreeCheckCallback"></tree-view>
      </div>
    </div>

    <div class="lz-modal__footer lz-modal__footer__line flex_shrink__0">
      <el-button type="primary" size="small" class="lz-modal__footer__button confirm" @click="handleSaveClick">{{$t('确定')}}</el-button>
      <el-button size="small" class="lz-modal__footer__button cancel" @click="handleCloseClick">{{$t('取消')}}</el-button>
    </div>
  </div>
</template>

<script>
import { intl } from "@tci18n/vue2";import { mapState } from 'vuex';
import SearchList from '../common/search.vue';
import TreeView from '../common/tree.vue';
import deptAPI from '../api/dept';

/**
 * 部门选择
 * @module @/view/orgnaization
 */
export default {
  name: 'SelectDept',
  components: {
    SearchList,
    TreeView
  },
  props: {
    deptId: {
      type: Number | String,
      default: -1
    },

    deptName: {
      type: String,
      default: ''
    },

    depts: {
      type: Array,
      default: function () {
        return [];
      }
    },

    // tree 节点是否可单选
    showRadio: {
      type: Boolean,
      default: false
    },

    // tree 节点是否可勾选
    showCheckbox: {
      type: Boolean,
      default: false
    },

    // tree 组件是否遵循父子不互相关联勾选
    checkStrictly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // search-list 组件 props
      searchProps: {
        value: 'id',
        label: 'name'
      },
      searchKeyword: '',
      // 搜索结果
      searchList: undefined,

      // tree 组件 props
      treeProps: {
        label: 'deptName'
      },

      // 选择的数据
      selectedValue: {
        deptId: this.deptId,
        deptName: this.deptName
      },
      // 勾选的数据
      checkedValue: [...this.depts]
    };
  },
  computed: {
    ...mapState('org', [
    'deptTreeData']
    ),

    // root 节点是否是 disabled 状态
    rootDisbaled() {
      return !(this.deptTreeData && this.deptTreeData[0].permission);
    },

    // “快捷选择”控件是否 enable
    shortcutsEnable() {
      return this.showCheckbox && this.treeData.length;
    },

    // tree view data
    treeData() {
      if (!this.deptTreeData) {
        return [];
      } else if (this.rootDisbaled) {
        return this.deptTreeData[0].children;
      } else {
        return this.deptTreeData;
      }
    },

    // 勾选的数据 id 集合
    checkedIdValue() {
      return this.checkedValue.map((item) => item.id);
    },

    // 获取组件引用
    searchListRef() {
      return this.$refs['searchList'];
    },

    treeViewRef() {
      return this.$refs['treeView'];
    }
  },
  mounted() {
    this.initTreeData();
  },
  methods: {
    /**
     * 设置视图 loading 状态
     */
    viewLoading(loading) {
      this.$emit('loading', loading);
    },

    /**
     * 是否处于搜索状态
     */
    isSearchingState() {
      return this.$refs['searchList'] != undefined && this.$refs['searchList'].isSearching;
    },

    /** 搜索相关 **/
    /**
     * 搜索部门
     * @param { String } keyword 搜素关键字
     */
    searchDept(keyword) {
      const params = {
        name: keyword,
        // 只查询部门
        queryType: 1
      };
      this.viewLoading(true);
      deptAPI.deptSearch(params).then((res) => {
        this.viewLoading(false);
        if (res.code == 200 && res.data) {
          this.searchKeyword = keyword;
          this.searchList = res.data;

          if (this.showRadio) {
            this.searchListRef.setSelected(this.selectedValue.deptId);
          } else if (this.showCheckbox) {
            this.searchListRef.setChecked(this.checkedIdValue);
          }
        } else {
          this.$notice.error(res.message);
        }
      }).catch((error) => {
        this.viewLoading(false);
        this.$notice.error(intl.$t("系统异常，请稍后再试"));
        console.error('Search dept error: ', error.message);
      });
    },

    /**
     * 搜索事件回调
     */
    handleSearchCallback(value) {
      if (this.searchKeyword != value) {
        this.searchList = undefined;
        this.searchDept(value);
      }
    },

    /**
     * 搜索取消事件回调
     */
    handleSearchCancelCallback() {
      this.searchList = undefined;
      this.searchKeyword = '';
    },

    /**
     * 搜索项 select 回调事件
     * @param { Object } data 数据项
     */
    handleSearchSelectChangeCallback(data) {
      this.selectedValue.deptId = data.id;
      this.selectedValue.deptName = data.name;

      // 同步 tree 组件 selected
      this.treeViewRef.setNodeSelected(this.selectedValue.deptId);
    },

    /**
     * 搜索项 check 回调事件
     * @param { Object } data 数据项
     * @param { Boolean } isChecked 是否勾选
     */
    handleSearchCheckChangeCallback(data, isChecked) {
      const index = this.checkedValue.findIndex((item) => item.id == data.id);
      if (isChecked && index === -1) {
        this.checkedValue.push(data);
      } else if (!isChecked && index !== -1) {
        this.checkedValue.splice(index, 1);
      }
      // 同步 tree 组件 checked
      this.treeViewRef.setNodeCheckState(data.id, isChecked);
    },


    /** tree 相关 **/
    initTreeData() {
      if (this.showRadio && this.deptId !== -1 && this.deptId !== '') {
        this.treeViewRef.setData(this.treeData, [this.deptId]);
        this.treeViewRef.setNodeSelected(this.deptId);
      } else if (this.showCheckbox && this.checkedValue.length) {
        this.treeViewRef.setData(this.treeData, this.checkedIdValue);
        this.treeViewRef.setNodesChecked(this.checkedIdValue);
      } else {
        this.treeViewRef.setData(this.treeData);
      }
    },

    /**
     * tree radio 改变回调事件
     * @param { Object } data 选择的数据
     */
    handleTreeRadioChangeCallback(data) {
      this.selectedValue.deptId = data.deptId;
      this.selectedValue.deptName = data.deptName;
    },

    /**
     * tree check 改变回调事件
     * @param { Array } data 勾选的数据
     */
    handleTreeCheckCallback(data) {
      this.checkedValue = data.map((item) => {
        return {
          id: item.deptId,
          name: item.deptName
        };
      });
    },

    /**
     * “选择全部/取消选择”点击事件
     * @param { Boolean } checked 是否是勾选操作
     */
    handleTreeCheckAllClick(checked) {
      this.treeViewRef.setTreeCheckAllState(checked);
    },



    /** footer 操作按钮相关 **/
    /**
     * 保存点击事件
     */
    handleSaveClick() {
      let data,
        error = false;
      if (this.showRadio) {
        if (this.selectedValue.deptId === -1) {
          error = true;
        } else {
          data = this.selectedValue;
        }
      } else if (this.showCheckbox) {
        if (!this.checkStrictly) {
          const checkedValue = this.treeViewRef.getTopCheckedData();
          this.checkedValue = checkedValue.map((item) => {
            return {
              id: item.deptId,
              name: item.deptName
            };
          });
        }
        if (this.checkedValue.length === 0) {
          error = true;
        } else {
          data = this.checkedValue;
        }
      }
      if (error) {
        this.$notice.error({
          title: intl.$t("操作失败"),
          message: intl.$t("请选择部门")
        });
        return;
      }
      this.$emit('confirm', data);
    },

    /**
     * 关闭点击事件
     */
    handleCloseClick() {
      this.$emit('input', false);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/const";

  .modal-box {
    height: 100%;
    overflow: hidden;
  }

  .modal-body {
    height: calc(100% - 52px - 10px);
    overflow: hidden;
    position: relative;
    margin-left: 16px;
    margin-right: 16px;

    .tree-section {
      height: calc(100% - 40px);
      overflow: auto;
    }

    .action-view {
      padding-top: 8px;
      padding-bottom: 6px;

      .action-item {
        height: 22px;
        color: $theme-color;
        padding: 0;
      }

      .action-item + .action-item {
        margin-left: 15px;
      }
    }
  }
</style>
