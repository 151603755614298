import { intl } from "@tci18n/vue2"; // SearchSelectList options 第一项特殊值（“选通用角色”）
const SEARCH_LIST_OPTION_SPECIAL = {
  id: -99,
  name: intl.$t("选通用角色")
};

// 角色类型枚举（与后端定义一致）
const ROLE_TYPE_GENERAL_ENUM = 1;
const ROLE_TYPE_HOTEL_ENUM = 0;


import roleAPI from '../../../api/role';

/**
 * Member hotel role set hotel role mixin
 */
export default {
  data() {
    return {
      // 角色数据配置选项
      roleProps: {
        value: 'id',
        label: 'name',
        disabled: 'disabled',
        type: 'type'
      },
      // 门店角色信息（{ code(hotelVid): [...] }）
      roleListData: {},
      showRoleSelect: false,


      // 已选中的门店数据项
      hotelCheckedItems: [],
      // 已选中的“通用角色”数据项
      generalRoleItem: null
    };
  },
  computed: {
    // role props
    rolePropValue() {
      return this.roleProps.value;
    },

    rolePropLabel() {
      return this.roleProps.label;
    },

    rolePropDisabled() {
      return this.roleProps.disabled;
    },

    rolePropType() {
      return this.roleProps.type;
    }
  },
  methods: {
    initHotelRoleData() {
      // TODO：暂支持配置一个数据，但数据字段为 Array
      this.generalRoleItem = this.generalRoleList.length && this.generalRoleList[0] || null;
      this.hotelCheckedItems = this.hotelRoleList.map((item) => {
        let data = {
          code: item.code || item.hotelVid,
          name: item.name,
          roleList: item.roleList,
          showRoleSelect: false
        };
        return data;
      });
    },

    /**
     * 是否角色类型是“通用角色”
     * @param { Number } type 角色类型
     */
    isRoleTypeGeneral(type) {
      return type === ROLE_TYPE_GENERAL_ENUM;
    },

    /**
     * 是否角色类型是“酒店角色”
     * @param { Number } type 角色类型
     */
    isRoleTypeHotel(type) {
      return type === ROLE_TYPE_HOTEL_ENUM;
    },


    /** 角色配置相关 **/
    /**
     * 同步酒店项勾选状态更改，更新“已选”酒店列表
     * @param { Object } data 数据项
     * @param { Boolean } checked true/false
     */
    syncHotelItemCheckState(data, checked) {
      let checkedItems = this.hotelCheckedItems;
      const index = checkedItems.findIndex((item) => item.code == data.code);
      if (checked && index === -1) {
        let roleList = [];
        if (this.generalRoleItem) {
          // TODO：暂支持配置一个数据，但数据字段为 Array
          roleList = [{
            [this.rolePropValue]: this.generalRoleItem[this.rolePropValue],
            [this.rolePropLabel]: this.generalRoleItem[this.rolePropLabel],
            [this.rolePropDisabled]: false,
            [this.rolePropType]: ROLE_TYPE_GENERAL_ENUM
          }];
        }
        checkedItems.push({
          code: data.code,
          name: data.name,
          roleList,
          showRoleSelect: false
        });
      } else if (!checked && index !== -1) {
        checkedItems.splice(index, 1);
      }
    },

    /**
     * 角色配置视图 - 移除酒店角色设置项
     * @param { Number } index 数据索引
     */
    handleRemoveHotelRoleItem(index) {
      const removeItem = this.hotelCheckedItems.splice(index, 1)[0];
      // 同步 tree 视图节点勾选状态
      this.syncTreeHotelNodeCheckState(removeItem.code, false);
    },


    /** 角色下拉选择组件相关 */
    /**
     * 获取酒店下配置的角色列表
     * @param { Number } hotelVid
     */
    getHotelRoleList(hotelVid) {
      roleAPI.roleHotel({ hotelVid }).then((res) => {
        if (res.code == 200 && res.data) {
          this.$set(this.roleListData, hotelVid, res.data);
        }
      }).catch((error) => {
        console.error('Get hotel role list error: ', error.message);
      });
    },

    /**
     * 获取酒店角色配置数据
     * @note TODO：暂支持配置一个数据，但数据字段为 Array
     */
    getHotelRoleData(data) {
      return data.roleList && data.roleList.length && data.roleList[0] || null;
    },

    /**
     * 角色搜索下拉组件 - 过滤具有权限的角色列表
     * @note 无权限，数据中 disabled 字段为 true
     * @note 如果已设置通用角色，则在 roleList 起始位置插入 “选通用角色” 选项
     * @param { String } code 酒店 hotelVid
     * @param { Array } 过滤后的数据源
     */
    enabledRoleList(code) {
      const roleList = this.roleListData[code];
      if (!roleList) {
        return null;
      }
      let list = roleList.filter((item) => !item[this.rolePropDisabled]);
      if ((list.length === 0 || list[0][this.rolePropValue] != SEARCH_LIST_OPTION_SPECIAL[this.rolePropValue]) && this.generalRoleItem) {
        list.unshift(SEARCH_LIST_OPTION_SPECIAL);
      }
      return list;
    },

    /**
     * 角色搜搜下拉组件 - Popover 视图显示事件
     * @note 如果未获取对应酒店角色列表，展示时请求酒店配置的角色列表
     * @param { Number } code 酒店 hotelVid
     */
    handleRolePopoverShow(code) {
      if (!this.roleListData[code]) {
        this.getHotelRoleList(code);
      }
    },

    /**
     * 角色搜索下拉组件 - 角色列表是否是请求中
     */
    isLoadingRoleList(code) {
      return !this.roleListData[code];
    },

    /**
     * 角色搜索下拉组件 - 酒店角色项显示数据
     * @note
     * - 如果当前操作人无已绑定的“门店角色”权限（disabled 判断当前操作人是否有当前绑定的角色权限），则返回默认值
     * - 如果设置为门店角色，返回角色数据
     * - 如果设置为通用角色，返回“已选通用角色”数据
     */
    hotelRoleItemSelected(data) {
      let roleData = { [this.rolePropValue]: '', [this.rolePropLabel]: '' };
      const tmpData = this.getHotelRoleData(data);
      if (tmpData && this.isRoleTypeHotel(tmpData[this.rolePropType]) && !tmpData[this.rolePropDisabled]) {
        roleData[this.rolePropValue] = tmpData[this.rolePropValue];
        roleData[this.rolePropLabel] = tmpData[this.rolePropLabel];
      } else if (tmpData && this.isRoleTypeGeneral(tmpData[this.rolePropType]) && this.generalRoleItem) {
        roleData[this.rolePropValue] = SEARCH_LIST_OPTION_SPECIAL[this.rolePropValue];
        roleData[this.rolePropLabel] = intl.$t("已选通用角色");
      }
      return roleData;
    },

    /**
     * 角色搜索下拉组件 - 角色搜索列表选择回调事件
     * @note 选择通用角色后，同步酒店角色选中该角色
     * @param { Number } index hotelCheckedItems 索引（-1: 通用角色）
     * @param { Object } data 选择的数据项（index：角色数据项索引, id：数据项Id）
     */
    handleSearchSelectSelectedCallback(index, data) {
      if (index === -1) {
        this.showRoleSelect = false;
        this.generalRoleItem = {
          [this.rolePropValue]: this.generalRoleData[data.index][this.rolePropValue],
          [this.rolePropLabel]: this.generalRoleData[data.index][this.rolePropLabel]
        };
        this.syncHotelRoleAfterGeneralRoleSet();
      } else {
        let checkedItem = this.hotelCheckedItems[index];
        const enabledRoleList = this.enabledRoleList(checkedItem.code);
        const value = enabledRoleList[data.index][this.rolePropValue],
          label = enabledRoleList[data.index][this.rolePropLabel];
        let roleList;
        // 如果选择的是 “选通用角色” 选项，则“门店”角色变更为“通用”角色
        if (value === SEARCH_LIST_OPTION_SPECIAL[this.rolePropValue]) {
          roleList = [{
            [this.rolePropValue]: this.generalRoleItem[this.rolePropValue],
            [this.rolePropLabel]: this.generalRoleItem[this.rolePropLabel],
            [this.rolePropDisabled]: false,
            [this.rolePropType]: ROLE_TYPE_GENERAL_ENUM
          }];
        } else {
          roleList = [{
            [this.rolePropValue]: value,
            [this.rolePropLabel]: label,
            [this.rolePropDisabled]: false,
            [this.rolePropType]: ROLE_TYPE_HOTEL_ENUM
          }];
        }
        checkedItem.roleList = roleList;
        checkedItem.showRoleSelect = false;
      }
    },

    /**
     * 角色搜索下拉组件 - “通用角色”设置后处理逻辑
     * @note
     * - 对 hotelCheckedItems 中未设置角色的项设置为“通用”
     * - 对 hotelCheckedItems 中已设置“通用”的角色变更数据
     * - 对 hotelCheckedItems 中已设置但当前操作人无权限的“门店”角色变更为“通用”
     */
    syncHotelRoleAfterGeneralRoleSet() {
      this.hotelCheckedItems.
      filter((item) => {
        const roleData = this.getHotelRoleData(item);
        if (!roleData || roleData && roleData[this.rolePropDisabled] || roleData && this.isRoleTypeGeneral(roleData[this.rolePropType])) {
          return true;
        } else {
          return false;
        }
      }).
      forEach((item) => {
        item.roleList = [{
          [this.rolePropValue]: this.generalRoleItem[this.rolePropValue],
          [this.rolePropLabel]: this.generalRoleItem[this.rolePropLabel],
          [this.rolePropDisabled]: false,
          [this.rolePropType]: ROLE_TYPE_GENERAL_ENUM
        }];
      });
    }
  }
};