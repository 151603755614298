<template>
<div class="dialog-body">
    <div class="form-item__title">
      <span>{{$t('新密码')}}</span>
      <span class="suffix">{{$t('（您也可以手动设置密码）')}}</span>
    </div>

    <el-input v-model="content" :class="['form-item__content', { 'is-error': error }]" clearable @blur="handleResetPwdInputBlur"></el-input>
    <div v-if="error" class="form-item__error">{{message}}</div>
  </div>
</template>

<script>
import { regexPwd } from '@/libs/regexUtil'

/**
 * 重置密码
 * @module @/view/organization
 */
export default {
  name: 'ResetPassword',
  data () {
    return {
      content: '',
      error: false,
      message: ''
    }
  },
  methods: {
    /**
     * input blur 事件
     */
    handleResetPwdInputBlur () {
      this.validateFormItem();
    },

    /**
     * 校验标单项
     * @param { Function } callback 回调函数
     */
    validateFormItem (callback) {
      regexPwd({ required: true }, this.content, error => {
        if (error) {
          this.error = true;
          this.message = error.message;
          callback && callback(false);
        } else {
          this.error = false;
          this.message = '';
          callback && callback(true);
        }
      });
    },


    /** 外部调用 **/
    /**
     * 设置组件数据
     * @param { String } data 自动生成的密码文本
     */
    setData (data) {
      this.content = data;
    },

    /**
     * 获取组件数据
     * @param { Function } 回调函数
     */
    getData (callback) {
      this.validateFormItem(result => {
        if (result) {
          callback({ result: true, content: this.content });
        } else {
          callback({ result: false });
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/const";

  .dialog-body {
    position: relative;
    margin: 4px;

    .form-item__title {
      line-height: 1;

      span {
        vertical-align: middle;
      }

      .suffix {
        font-size: 12px;
        color: #C6CDD9;
      }
    }

    .form-item__content {
      margin-top: 12px;

      $_height: 36px;
      ::v-deep .el-input__inner {
        height: $_height;
        line-height: $_height;
      }

      ::v-deep .el-input__icon {
        line-height: $_height;
      }

      &.is-error ::v-deep .el-input__inner {
        border-color: $error-color;
      }
    }

    .form-item__error {
      font-size: 12px;
      color: $error-color;
      line-height: 1;
      margin-top: 3px;
      position: absolute;
    }
  }
</style>
