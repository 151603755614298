<template>
<div class="search-list">
    <div class="search-view display__flex">
      <div class="search-view__input flex_grow__1">
        <lz-icon type="iconfont" name="lzicon-search_outline icon" :size="16" @click.native="handleSearchClick"></lz-icon>
        <el-input class="search-input" v-model="keyword" :placeholder="placeholder" clearable @keydown.enter.native="handleSearchClick" @clear="handleSearchInputClear"></el-input>
      </div>

      <!-- 自定义搜索控件 -->
      <div v-if="customWidget" class="search-view__widget flex_shrink__0">
        <slot name="widget"></slot>
      </div>
    </div>
    
    <div v-if="isSearching" class="list-view mb-15" :class="{ 'mt-15': !shortcutsEnable }">
      <div v-if="shortcutsEnable" class="action-view">
        <el-button class="action-item" type="text" @click="handleCheckAllClick(true)">{{$t('选择全部')}}</el-button>
        <el-button class="action-item" type="text" @click="handleCheckAllClick(false
        )">{{$t('取消选中')}}</el-button>
      </div>
      <template v-if="searchList && searchList.length>0">
        <ul>
          <li v-for="item in searchList" :key="item[props.value]" class="list-item pl-5 display__flex align_items__center">
            <el-radio v-if="showRadio" class="list-item-radio" :value="selectedValue" :label="item[props.value]" @input="handleRadioChangeCallback(item)"><span>{{item[props.label]}}</span>
            </el-radio>
            <el-checkbox v-else-if="showCheckbox" class="list-item-checkbox" :value="isCheckboxChecked(item[props.value])" @change="checked => handleCheckboxChangeCallback(item, checked)"><span>{{item[props.label]}}</span>
            </el-checkbox>
          </li>
        </ul>
      </template>
      <template v-if="searchList && searchList.length===0">
        <div class="no-data-box">
          <img src="~@/assets/images/no-data.png"/>
          <div class="text">{{$t('没有查询到你想要的结果呢～')}}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
/**
 * 搜索列表
 */
export default {
  name: 'SearchList',
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    
    // 配置选项
    props: {
      type: Object,
      default: function () {
        return {
          value: 'value',
          label: 'label'
        }
      }
    },

    // tree 节点是否可单选
    showRadio: {
      type: Boolean,
      default: false
    },

    // tree 节点是否可勾选
    showCheckbox: {
      type: Boolean,
      default: false
    },

    // 是否展示“快捷选择”控件
    showShortcuts: {
      type: Boolean,
      default: false
    },

    // 搜索结果列表
    searchList: Array
  },
  data () {
    return {
      // 是否自定义搜索控件
      customWidget: false,

      isSearching: false,
      // 搜索关键字
      keyword: '',
      // selected value
      selectedValue: '',
      // checked value
      checkedValue: []
    }
  },
  computed: {
    // “快捷选择”控件是否 enable
    shortcutsEnable () {
      return this.showShortcuts && this.searchList && this.searchList.length;
    }
  },
  mounted () {
    this.customWidget = this.$slots.widget !== undefined;
  },
  methods: {
    /**
     * Checkbox 是否勾选
     */
    isCheckboxChecked (value) {
      return this.checkedValue.findIndex(checkedValue => checkedValue == value) !== -1;
    },

    /**
     * 搜索点击事件
     */
    handleSearchClick () {
      if (this.keyword.trim()) {
        this.isSearching = true;
        this.$emit('search', this.keyword.trim());
      } else {
        this.handleSearchInputClear();
      }
    },

    /**
     * 输入框 clear 事件
     */
    handleSearchInputClear () {
      this.isSearching = false;
      this.$emit('cancel');
    },


    /**
     * 搜索项 radio 状态改变事件
     */
    handleRadioChangeCallback (data) {
      this.selectedValue = data[this.props.value];
      this.$emit('select-change', data);
    },

    /**
     * 搜索项 checkbox 状态改变事件
     * @param { Object } data 数据项
     * @param { Boolean } value 勾选状态
     */
    handleCheckboxChangeCallback (data, value) {
      let tmpValue = data[this.props.value];
      let index = this.checkedValue.findIndex(checkedValue => checkedValue == tmpValue);
      if (value && index===-1) {
        this.checkedValue.push(tmpValue);
        this.$emit('check-change', data, value);
      } else if (!value && index!==-1) {
        this.checkedValue.splice(index, 1);
        this.$emit('check-change', data, value);
      }
    },

    /**
     * “选择全部/取消选择”点击事件
     * @param { Boolean } checked 是否是勾选操作
     */
    handleCheckAllClick (checked) {
      this.searchList.forEach(item => {
        this.handleCheckboxChangeCallback(item, checked);
      });
    },


    /** 外部调用 **/
    /**
     * 设置选中项
     * @param { String|Number } value 选中项数据
     */
    setSelected (value) {
      this.selectedValue = value;
    },

    /**
     * 设置已勾选项
     * @param { Array } value 已勾选项数据
     */
    setChecked (value) {
      this.checkedValue = value;
    },

    /**
     * 设置是否是搜索状态
     * @param { Boolean } isSearching true/false
     */
    setIsSearching (isSearching) {
      this.isSearching = isSearching;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/const";
  @import "@/style/mixins";

  .search-view {
    $_height: 38px;

    height: 40px;

    .search-view__input {
      height: 100%;
      border: 1px solid $border-color;
      border-radius: 2px;
    }

    .icon {
      width: $_height;
      height: $_height;
      line-height: $_height;
      color: $theme-color;
      text-align: center;
      vertical-align: middle;
      cursor: pointer;
    }

    .search-input {
      width: calc(100% - 38px);
      line-height: $_height;
      vertical-align: middle;

      ::v-deep .el-input__inner {
        border: none;
      }
    }

    .search-view__widget {
      height: 100%;
      margin-left: 12px;
    }
  }

  .list-view {
    position: absolute;
    top: 40px;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;

    .action-view {
      padding-top: 8px;
      padding-bottom: 6px;

      .action-item {
        height: 22px;
        color: $theme-color;
        padding: 0;
      }

      .action-item + .action-item {
        margin-left: 15px;
      }
    }
    
    .list-item {
      height: 40px;
      cursor: pointer;
    }

    .list-item:hover {
      background-color: #EDF3FF;
    }

    .list-item .list-item-radio,
    .list-item .list-item-checkbox {
      @include no-wrap-single;
    }

    ::v-deep .list-item-radio {
      .el-radio__inner {
        width: 16px;
        height: 16px;
      }

      .el-radio__inner::after {
        width: 5px;
        height: 5px;
      }
    }

    ::v-deep .list-item-checkbox {
      .el-checkbox__label {
        display: inline;
      }
    }


    .no-data-box {
      margin-top: 50px;
      text-align: center;

      img {
        width: 100px;
        height: 85px;
      }

      .text {
        color: #AAB1BD;
      }
    }
  }
</style>
