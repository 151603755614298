<template>
<div class="lz-drawer__box">
    <div class="lz-drawer__body">
      <el-form ref="form" class="lz-form--block" :model="formData" :rules="rules" label-width="85px">
        <el-form-item :label="$t('部门名称：')" prop="deptName">
          <el-input v-model="formData.deptName" :disabled="isDeptNameDisabled" :placeholder="$t('请输入部门名称')"></el-input>
        </el-form-item>
        <el-form-item v-if="isHotelShowable">
          <template slot="label">
            <el-popover style="display: inline-block;" :width="280" trigger="hover" palcement="bottom">
              <div slot="reference" class="popover-reference display__flex align_items__center">
                <span>{{$t('显示名称')}}</span>
                <lz-icon type="iconfont" name="lzicon-question_circle_outline" color="#474F64" :size="12"></lz-icon>
              </div>
              <span>{{$t('选择某个酒店作为显示名称后，当前部门名称会跟随酒店名称的变更而变更')}}</span>
            </el-popover>
          </template>

          <el-select v-model="formData.hotelVid" filterable clearable :placeholder="$t('请选择酒店')" @change="handleHotelSelectChange">
            <el-option v-for="item in hotelList" :key="item.hotelVid" :label="item.hotelName" :value="item.hotelVid"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('上级部门：')" prop="deptPid">
          <span :class="['click-label', { grey: !isParentDeptValid }]" @click="handleShowModal">{{isParentDeptValid ? formData.deptPName : $t('请选择上级部门')}}</span>
        </el-form-item>
      </el-form>
    </div>
    
    <div class="lz-drawer__footer lz-drawer__footer__line">
      <el-button v-if="isDeptDeletable" type="danger" class="lz-drawer__footer__button" @click="handleDeleteClick">{{$t('删除')}}</el-button>
      <el-button v-if="isDeptSavable" type="primary" class="lz-drawer__footer__button confirm" @click="handleSaveClick">{{$t('保存')}}</el-button>
      <el-button class="lz-drawer__footer__button cancel" @click="handleCloseClick">{{$t('取消')}}</el-button>
    </div>

    <!-- 上级部门 -->
    <lz-modal v-model="showModal" name="change-parent-dept" width="586px" :title="modalConfig.title" :mask-closable="false" loading-enable :loading="modalLoading" footer-hide>
      <div slot="body" style="height: 580px;">
        <select-dept v-if="showModal" v-model="showModal" :dept-id="formData.deptPid" :dept-name="formData.deptPName" :show-radio="true" @loading="(loading) => { modalLoading = loading }" @confirm="handleSelectDeptCallback"></select-dept>
      </div>
    </lz-modal>
  </div>
</template>

<script>
import { intl } from "@tci18n/vue2";import { mapGetters } from 'vuex';
import SelectDept from './selectDept.vue';
import deptAPI from '../api/dept';

/**
 * 部门新增/编辑
 * @module @/view/organization
 */
export default {
  name: 'DeptEdit',
  components: {
    SelectDept
  },
  props: {
    // 操作类型（1-新增组织，2-编辑组织）
    action: {
      type: Number,
      default: 1
    },

    // 部门数据
    data: Object,

    // 酒店列表
    hotelList: Array
  },
  data() {
    return {
      formData: {
        // 部门
        deptId: -1,
        deptName: '',
        // 上级部门（未指定则为-1）
        deptPid: -1,
        deptPName: '',
        // 酒店关联
        hotelVid: ''
      },

      rules: {
        deptName: [
        { required: true, whitespace: true, message: intl.$t("请输入部门名称"), trigger: 'blur' }],

        deptPid: [
        { required: true, min: 0, message: intl.$t("请选择上级部门"), trigger: 'change' }]

      },

      modalLoading: false,
      // 上级部门
      showModal: false,
      modalConfig: {
        title: intl.$t("选择部门")
      }
    };
  },
  computed: {
    ...mapGetters([
    'pageButtonPermission',
    'isTenantTypeBlocUser']
    ),

    // 是否是“编辑”操作
    isActionEdit() {
      return this.action === 2;
    },

    // 父级部门是否指定
    isParentDeptValid() {
      return this.formData.deptPid != -1;
    },

    // 部门名称是否 disabled 状态
    isDeptNameDisabled() {
      return this.formData.hotelVid !== '' && this.formData.hotelVid != undefined;
    },

    // “显示名称”是否可配置（“集团租户”用户可配置）
    isHotelShowable() {
      return this.isTenantTypeBlocUser;
    },

    // 是否具有部门“保存”权限
    isDeptSavePermission() {
      return this.pageButtonPermission('hrms/organization', 'addDeptButton') || this.pageButtonPermission('hrms/organization', 'updateDeptButton');
    },

    // 是否具有部门“删除”权限
    isDeptDeletePermission() {
      return this.pageButtonPermission('hrms/organization', 'deleteDeptButton');
    },

    // 部门”保存“按钮是否可见
    isDeptSavable() {
      return this.isDeptSavePermission;
    },

    // 部门”删除“按钮是否可见
    isDeptDeletable() {
      return this.isActionEdit && this.isDeptDeletePermission;
    }
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.formData.deptId = this.data.deptId !== '' ? this.data.deptId : -1;
      this.formData.deptName = this.data.deptName;
      this.formData.deptPid = this.data.deptPid !== '' ? this.data.deptPid : -1;
      this.formData.deptPName = !this.isActionEdit || this.data.pPermission ? this.data.deptPName : intl.$t("无法查看上级部门");
      if (this.isActionEdit && this.isHotelShowable) {
        this.formData.hotelVid = this.data.hotelVid;
      }
    },

    handleShowModal() {
      this.showModal = true;
    },
    /**
     * 设置视图 loading 状态
     */
    viewLoading(loading) {
      this.$emit('loading', loading);
    },

    /**
     * 删除点击事件
     */
    handleDeleteClick() {
      this.$emit('delete', this.data.deptId);
    },

    /**
     * 保存点击事件
     */
    handleSaveClick() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.saveDept(this.formData);
        }
      });
    },

    /**
     * 关闭抽屉事件
     */
    handleCloseClick() {
      this.$emit('input', false);
    },


    /** 显示名称 **/
    /**
     * 酒店下拉选择改变事件
     */
    handleHotelSelectChange(value) {
      let hotelData = this.hotelList.find((item) => item.hotelVid == value);
      if (hotelData) {
        this.formData.deptName = hotelData.hotelName;
        this.$refs['form'].validateField('deptName');
      }
    },


    /** 上级部门 **/
    /**
     * 选择上级部门成功回调
     * @param { Number } deptId 新部门 id
     * @param { String } deptName 新部门名称
     */
    handleSelectDeptCallback({ deptId, deptName }) {
      this.formData.deptPid = deptId;
      this.formData.deptPName = deptName;
      this.showModal = false;
    },


    /** 新增/编辑 **/
    /**
     * 保存部门
     */
    saveDept(data) {
      const params = {
        deptName: data.deptName.trim(),
        deptPid: data.deptPid,
        hotelVid: data.hotelVid || '',
        state: 1
      };
      if (this.isActionEdit) {
        params.deptId = data.deptId;
      }
      this.viewLoading(true);
      deptAPI.deptSave(params).then((res) => {
        this.viewLoading(false);
        if (res.code == 200) {
          this.$notice.success(this.isActionEdit ? intl.$t("编辑") : intl.$t("新增") + intl.$t("部门成功"));

          this.$emit('save');
          this.handleCloseClick();
        } else {
          this.$notice.error(res.message);
        }
      }).catch((error) => {
        this.viewLoading(false);
        this.$notice.error(intl.$t("系统异常，请稍后再试"));
        console.error('Save dept error: ', error.message);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/const";
  @import "@/style/mixins";

  .lz-form--block {
    .popover-reference {
      line-height: 1;
      cursor: help;

      &::after {
        content: '：';
      }
    }

    .click-label {
      width: 220px;
      height: 32px;
      line-height: 30px;
      padding: 0 10px;
      border: 1px solid #DEE3EE;
      border-radius: 2px;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      overflow: hidden;
      transition: border 0.2s ease-in-out, background 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
      @include no-wrap-single;

      &.grey {
        color: #C6CDD9;
      }

      &:hover {
        border-color: $theme-color;
      }
    }
  }
</style>
